import React from "react";

interface ContextProps {
  selectedMetaCategories: string[];
  setSelectedMetaCategories: (categories: string[]) => void;
}

export const CategorySearchContext = React.createContext<Partial<ContextProps>>(
  {}
);
