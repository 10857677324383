import React from "react";
import { StandortGenerischIcon, StandortIcon } from "styles/icons";

interface Props {
  size: string;
  lat: number;
  lng: number;
}
export const DefaultMarker: React.FC<Props> = ({ size }) => (
  <StandortIcon boxSize={size} />
);
export const DefaultMarkerGeneric: React.FC<Props> = ({ size }) => (
  <StandortGenerischIcon boxSize={size} />
);
