import { QuestionIcon, IconProps } from "@chakra-ui/icons";
import React from "react";
import {
  BeautyIcon,
  BuecherIcon,
  BueroTechnikIcon,
  CoronaIcon,
  DienstleistungIcon,
  GesichtsmaskenIcon,
  GesundheitIcon,
  HandwerkIcon,
  HausGartenIcon,
  HaustiereIcon,
  HobbyIcon,
  KinderIcon,
  KleidungIcon,
  KunstIcon,
  LebensmittelIcon,
  RestaurantIcon,
  SchmuckIcon,
  SchuheIcon,
  SonstigesIcon,
  TaschenIcon,
} from "styles/icons";

interface Props extends IconProps {
  name: string;
}

export const CategoryIcon: React.FC<Props> = ({ name, ...rest }) => {
  switch (name) {
    case "lebensmittel": {
      return <LebensmittelIcon {...rest} />;
    }
    case "bueroTechnik": {
      return <BueroTechnikIcon {...rest} />;
    }
    case "beauty": {
      return <BeautyIcon {...rest} />;
    }
    case "restaurant": {
      return <RestaurantIcon {...rest} />;
    }
    case "kleidung": {
      return <KleidungIcon {...rest} />;
    }
    case "handwerk": {
      return <HandwerkIcon {...rest} />;
    }
    case "gesundheit": {
      return <GesundheitIcon {...rest} />;
    }
    case "schuhe": {
      return <SchuheIcon {...rest} />;
    }
    case "kunst": {
      return <KunstIcon {...rest} />;
    }
    case "taschen": {
      return <TaschenIcon {...rest} />;
    }
    case "sonstiges": {
      return <SonstigesIcon {...rest} />;
    }
    case "schmuck": {
      return <SchmuckIcon {...rest} />;
    }
    case "dienstleistung": {
      return <DienstleistungIcon {...rest} />;
    }
    case "hausGarten": {
      return <HausGartenIcon {...rest} />;
    }
    case "kinder": {
      return <KinderIcon {...rest} />;
    }
    case "buecher": {
      return <BuecherIcon {...rest} />;
    }
    case "gesichtsmasken": {
      return <GesichtsmaskenIcon {...rest} />;
    }
    case "hobby": {
      return <HobbyIcon {...rest} />;
    }
    case "corona": {
      return <CoronaIcon {...rest} />;
    }
    case "haustiere": {
      return <HaustiereIcon {...rest} />;
    }
    default: {
      return <QuestionIcon {...rest} />;
    }
  }
};
