import React from "react";
import { Box, Flex, BoxProps } from "@chakra-ui/react";
import { ButtonLink } from "../buttonLink";
import { NextArrowIcon, NextArrowInactiveIcon, PrevArrowIcon, PrevArrowInactiveIcon } from "styles/icons";

interface Props extends BoxProps {
  prevLink?: string;
  nextLink?: string;
  onClick: () => void;
}

export const SitePager: React.FC<Props> = ({
  prevLink,
  nextLink,
  onClick,
  ...rest
}) => {

  const prev = prevLink ? prevLink : "";
  const next = nextLink ? nextLink : "";

  return (
    <Flex>
      {
        prev.length > 0 ? (
          <ButtonLink
            to={prev}
            variant="unstyled"
            width="50px"
            height="40px"
            display="table"
            mr="14px"
            onClick={() => onClick()}

            _focus={{ textDecoration: "none" }}>
            <PrevArrowIcon
              width="50px"
              height="40px"
            />
          </ButtonLink>
        ) : (
            <Box
              width="50px"
              height="40px"
              backgroundColor="lightestBlack"
              mr="14px"
              display="table"
            >
              <PrevArrowInactiveIcon
                width="50px"
                height="40px"
              />
            </Box>
          )
      }
      {
        next.length > 0 ? (
          <ButtonLink
            to={next}
            variant="unstyled"
            width="50px"
            height="40px"
            display="table"
            onClick={() => onClick()}
            _focus={{ textDecoration: "none" }}>
            <NextArrowIcon
              width="50px"
              height="40px"
            />
          </ButtonLink>
        ) : (
            <Box
              width="50px"
              height="40px"
              backgroundColor="lightestBlack"
              display="table"
            >
              <NextArrowInactiveIcon
                width="50px"
                height="40px"
              />
            </Box>
          )
      }
    </Flex>
  )
};
