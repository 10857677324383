import { Text } from "@chakra-ui/react";
import { CategoryIcon } from "app/categories/categoryMultiSelect/CategoryIcon";
import { CategoryIcons } from "app/categories/constants";
import React from "react";

interface Props {
  categoryName: string;
}

export const MarkerIcon: React.FC<Props> = ({ categoryName }) => {
  const categoryIcon = CategoryIcons.find(
    (c) => c.categoryName === categoryName
  );

  return categoryIcon?.iconName ? (
    <CategoryIcon name={categoryIcon.iconName} width={"28px"} height={ "24px" } />
  ) : (
    <Text>X</Text>
  );
};
