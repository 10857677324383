import React, { useContext } from "react";
import { NewsRepository } from "./NewsRepository";

interface RepositoryContextProps {
  newsRepository: NewsRepository;
}

export const NewsRepositoryContext = React.createContext<
  RepositoryContextProps
>({} as RepositoryContextProps);

export const useNewsRepositoryContext = () =>
  useContext(NewsRepositoryContext);
