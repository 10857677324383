import { computed, flow, observable } from "mobx";
import { News, NewsDetails } from "../models/index";
import { getNews, getNewsDetails } from "../services/index";


export class NewsRepository {
  @observable private _news: News[] = [];
  @observable private _presse: News[] = [];

  @observable private _newsDetail: NewsDetails = {} as any;


  constructor(typename?: string) {
    this.fetchNews();
  }

  fetchNews = flow(function* (this: NewsRepository) {
    try {
      const allNews: News[] = yield getNews("news");
      this._news = allNews;

    } catch (error) {
      console.error(error);
    }
  }).bind(this);

  //fetchPresse = flow(function* (this: NewsRepository) {
  //  try {
  //    const allPresse: News[] = yield getNews("presse");
  //    this._presse = allPresse;

  //  } catch (error) {
  //    console.error(error);
  //  }
  //}).bind(this);

  fetchNewsDetail = flow(function* (this: NewsRepository, newsId: string) {
    try {
      const details: NewsDetails = yield getNewsDetails(newsId);
      this._newsDetail = details;

    } catch (error) {
      console.error(error);
    }
  }).bind(this);

  @computed get news(): News[] {
    return this._news;
  }
  @computed get presse(): News[] {
    return this._presse;
  }
  @computed get newsDetail(): NewsDetails {
    return this._newsDetail;
  }
}
