import { Text } from "@chakra-ui/react";
import { LayoutGrid } from "app/shared";
import { StoreCard } from "app/stores";
import { Store } from "app/stores/models";
import { getFavoriteStores } from "app/stores/services";
import i18next from "i18next";
import React from "react";
import { UserId } from "../models";
import { SearchForUserParam } from "../../search/models";
import { useMediaQueryContext } from "styles/context";

export const OwnedFavoritesGrid: React.FC<SearchForUserParam> = ({ userId }) => {
  const { isSmall, isSmallMedium, isMedium, isMediumLarge, isLarge, isVeryLarge } = useMediaQueryContext();
  const numberOfColumns = isMedium ? 1 : 2;
  return (

    <LayoutGrid
      fetchId={userId}
      fetchItems={getFavoriteStores}
      loadMore={i18next.t("users:profile.ownedFavoritesGrid.loadMore")}
      numColumns={numberOfColumns}
      view={(item: Store) => <StoreCard mr={10} store={item} showTriangle={false} />}
      emptyStateView={() => (
        <Text fontSize="lg" color="darkGrey" fontWeight="bold" pt={20}>
          {i18next.t("users:profile.ownedFavoritesGrid.emptyState")}
        </Text>
      )}
      columnWidth={isMedium ? "100%" : isMediumLarge ? "50%" : "574px"}
      hideBottomButton={true}
    />
  )
};
