import { computed, flow, observable } from "mobx";
import { Category, CategoryDTO, MetaCategory } from "../models";
import { getCategories } from "../services/index";
import { formatCategoriesDTO } from "./utils";

export class CategoryRepository {
  @observable private _metaToCategories: MetaCategory[] = [];
  @observable private _categories: Category[] = [];

  constructor() {
    this.fetchCategories();
  }

  fetchCategories = flow(function* (this: CategoryRepository) {
    try {
      const allCategories: CategoryDTO[] = yield getCategories();
      const formattedCategories = formatCategoriesDTO(allCategories);

      this._metaToCategories = formattedCategories.metaCategories;
      this._categories = formattedCategories.categories;
    } catch (error) {
      console.error(error);
    }
  }).bind(this);

  @computed get metaCategories(): MetaCategory[] {
    return this._metaToCategories;
  }

  @computed get categories(): Category[] {
    return this._categories;
  }
}
