import { Box, Text } from "@chakra-ui/react";
import { Heading } from "app/shared";
import i18next from "i18next";
import React from "react";

export const CategorySearchHeader: React.FC = () => (
  <Box
    pb={10}
    maxWidth={["270px", "maxWidth"]}
    textAlign={["center", "center", "center", "unset"]}>
    <Heading>{i18next.t("search:categorySearchHeader.header")}</Heading>
    <Text fontWeight="bold" fontSize={["md", "md", "lg"]} color="lightBlack">
      {i18next.t("search:categorySearchHeader.subHeader")}
    </Text>
  </Box>
);
