import { Marker } from "app/shared/maps/Marker";
import { MarkerIcon } from "app/shared/maps/MarkerIcon";
import { StoreForMaps, Store } from "app/stores/models";
import React, { constructor, useRef, RefAttributes, RefObject } from "react";
import { useRootRepositoryContext } from "rootRepository";
import { useSearchRepositoryContext } from "../repositories";
import ReactDOM from "react-dom";

interface Props {
  store: StoreForMaps;
  isHoveredOnList: boolean;
  lat: number;
  lng: number;
  onClickCustom: (obj: Element, store: any) => void;
  thisMarker?: Element | null | Text;
}

//export const StoreMarker: React.FC<Props> = ({
//  lat,
//  lng,
//  store,
//  isHoveredOnList,
//  onClick,
//  refForMarker
//}) => {
//  const { searchRepository } = useSearchRepositoryContext();
//  const { categoryRepository } = useRootRepositoryContext();

//  let category = searchRepository.category;
//  let categoryName: string = category
//    ? categoryRepository.categories.find(c => c.id === category)?.categoryName ?? ""
//    : "";

//  function GetStoreMarker(): Element {
//    return ReactDOM.findDOMNode(refForMarker.current) as Element;
//  }

//  return (
//    <Marker
//      ref={refForMarker}
//      onClick={() => null}
//      lat={lat}
//      lng={lng}
//      bg={isHoveredOnList ? "darkGrey" : "brandRed.300"}>
//      <MarkerIcon categoryName={"default"} />
//    </Marker>

//  );
//}


export class StoreMarker extends React.Component<Props> {
  public lat?: number;
  public lng?: number;
  public bg?: string;
  public store?: StoreForMaps;
  public onClickCustom: (obj: Element, props: any) => void;
  public thisMarker?: Element | null | Text;
  public isHoveredOnList?: boolean;

  constructor(props: Props) {
    super(props);

    const defaultRect = { left: 0, width: 0 };

    this.state = {
      containerRect: defaultRect,
      tooltipRect: defaultRect
    };

    this.onClickCustom = props.onClickCustom;

    this.lat = props.lat;
    this.lng = props.lng;
    if (props.isHoveredOnList) {
      this.bg = "darkGrey";
    }
    else {
      this.bg = "brandRed.300"
    }
    this.store = props?.store;

  }

  componentDidMount() {
    this.setRef();
  }

  setRef() {
    if (ReactDOM.findDOMNode(this) != null && ReactDOM.findDOMNode(this) != undefined) {
      this.thisMarker = ReactDOM.findDOMNode(this);
    }
  }


  render() {
    return (
      <Marker
        onClick={() => {
          if (this.thisMarker == null || this.thisMarker == undefined) {
            this.setRef();
          }
          this.onClickCustom(this.thisMarker as Element, this.store);
        }}
        lat={this.lat ? this.lat : 0}
        lng={this.lng ? this.lng : 0}
        bg={this.bg}
        width="28px"
        borderRadius="40px"
        border="2px solid"
        borderColor="brandRed.600">
        <MarkerIcon categoryName={"default"} />
      </Marker>
    );
  }
}

