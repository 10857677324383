import { computed, flow, observable } from "mobx";
import { Presse, PresseDetails } from "../models/index";
import { getNews, getNewsDetails } from "../services/index";


export class PresseRepository {
  @observable private _presse: Presse[] = [];
  @observable private _presseDetail: PresseDetails = {} as any;


  constructor() {
    this.fetchNews();
  }

  fetchNews = flow(function* (this: PresseRepository) {
    try {
      const allNews: Presse[] = yield getNews();
      this._presse = allNews;

    } catch (error) {
      console.error(error);
    }
  }).bind(this);


  fetchPresseDetail = flow(function* (this: PresseRepository, newsId: string) {
    try {
      const details: PresseDetails = yield getNewsDetails(newsId);
      this._presseDetail = details;

    } catch (error) {
      console.error(error);
    }
  }).bind(this);


  @computed get presse(): Presse[] {
    return this._presse;
  }
  @computed get presseDetail(): PresseDetails {
    return this._presseDetail;
  }
}
