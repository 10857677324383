import i18next from "i18next";

type I18nString = string;

interface InfoText {
  header: I18nString;
  desc: I18nString;
}

export const LeftColumn: InfoText[] = [
  {
    header: i18next.t("shared:infoGrid.alternatives.header"),
    desc: i18next.t("shared:infoGrid.alternatives.desc"),
  },
  {
    header: i18next.t("shared:infoGrid.qualityOverQuantity.header"),
    desc: i18next.t("shared:infoGrid.qualityOverQuantity.desc"),
  },
  {
    header: i18next.t("shared:infoGrid.convenience.header"),
    desc: i18next.t("shared:infoGrid.convenience.desc"),
  },
];

export const RightColumn: InfoText[] = [
  {
    header: i18next.t("shared:infoGrid.helping.header"),
    desc: i18next.t("shared:infoGrid.helping.desc"),
  },
  {
    header: i18next.t("shared:infoGrid.free.header"),
    desc: i18next.t("shared:infoGrid.free.desc"),
  },
  {
    header: i18next.t("shared:infoGrid.solutions.header"),
    desc: i18next.t("shared:infoGrid.solutions.desc"),
  },
];
