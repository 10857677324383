import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import { compose } from "recompose";
import { Stack, Skeleton, Button, Box } from "@chakra-ui/react";
import { EditFormImageUploader } from "app/shared/forms/imageUploader/EditFormImageUploader";
import { ProductFormViewModel, model2ViewModel } from "./ProductInfoSchema";
import { BaseProductForm } from "./BaseProductForm";
import { Profile } from "../../../pages/Profile";
import { useProductRepositoryContext } from "../repositories";

interface Props {
  productId: string;
}

const EditProductFormBase: React.FC<Props> = ({ productId }) => {
  const { productRepository } = useProductRepositoryContext();

  useEffect(() => {
    autorun(() => productRepository.fetchProduct(productId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={20} direction="column">
      {productRepository.product.id ? (
        <Box>
          <Button variant="unstyled" width="215px" marginBottom="25px">
            <Link to="/user">zurück zum Profil</Link>
          </Button>
          <BaseProductForm
            initialState={model2ViewModel(productRepository.product)}
            imageUploaderComponent={EditFormImageUploader}
            productId={productRepository.product.id}
          />
        </Box>
      ) : (
        <Skeleton height="20px" my="10px" />
      )}
    </Stack>
  );
};

export const EditProductForm = compose<Props, Props>(observer)(
  EditProductFormBase
);
