import { FieldInternalProps, DEFAULT_LABEL_WIDTH } from "./GenericInput";
import { Field } from "formik";
import React, { useState } from "react";
import { FormControl, RadioGroup, Flex, Box, FormLabel } from "@chakra-ui/react";
import i18next from "i18next";
import { StyledRadio } from "./StyledRadio";

export interface GenericRadioGroupProps<T> {
  fieldName: string;
  fieldType: string;
  options: T[];
  i18nextPrefix: string;
  hideLabel?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  labelWidthPx?: string;
}

export const GenericRadioGroup = <T extends any>(
  props: GenericRadioGroupProps<T>
) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const { fieldName, fieldType, options, i18nextPrefix } = props;
  const disabled = props.disabled ?? false;
  const hideLabel = props.hideLabel ?? false;
  const defaultChecked = props.defaultChecked ?? false;
  const labelWidthPx = props.labelWidthPx || DEFAULT_LABEL_WIDTH;
  return (
    <Flex mt={3}>
      <Box width={labelWidthPx}>
        {!hideLabel && (
          <FormLabel>
            {i18next.t(`${i18nextPrefix}:form.fields.${fieldName}.label`)}
          </FormLabel>
        )}
      </Box>
      <Box flex="1">
        <Field className="block" name={fieldName} type={fieldType}>
          {(renderProps: FieldInternalProps) => (
            <FormControl
              isInvalid={
                renderProps.form.errors[fieldName] &&
                renderProps.form.touched[fieldName]
              }>
              <RadioGroup
                isInline
                onChange={(e: any) => {
                  setFirstLoad(false); 
                  renderProps.form.setFieldValue(
                    fieldName,
                    fieldType === "boolean"
                      ? e.target.value === "true"
                      : e.target.value
                  );
                  renderProps.form.setFieldTouched(fieldName, true);
                }}
                value={defaultChecked != undefined && firstLoad ? defaultChecked : renderProps.field.value}>
                {options.map((it: any, idx: number) => (
                  <StyledRadio
                    value={it}
                    isDisabled={disabled}
                    key={`radio_${fieldName}_${idx}`}>
                    {i18next.t(
                      `${i18nextPrefix}:form.fields.${fieldName}.options.${it}`
                    )}
                  </StyledRadio>
                ))}
              </RadioGroup>
            </FormControl>
          )}
        </Field>
      </Box>
    </Flex>
  );
};
