import { StoreComment, UserComment } from "../../models";
import { Chance } from "chance";
import { Ratings } from "app/shared/rating/constants";
import { MockStoreFactory } from "app/stores/services/mock/MockStoreFactory";

export class MockCommentFactory {
  static newStoreComment(overrides?: Partial<StoreComment>): StoreComment {
    const chance = new Chance();
    return {
      commentId: overrides?.commentId ?? chance.guid(),
      username: overrides?.username ?? `${chance.first()} ${chance.last()[0]}.`,
      userId: overrides?.userId ?? chance.guid(),
      rating:
        overrides?.rating ?? (chance.integer({ min: 0, max: 5 }) as Ratings),
      comment: overrides?.comment ?? chance.paragraph(),
      date: overrides?.date ?? chance.date(),
    };
  }

  static getStoreComments(n: number): StoreComment[] {
    const result = [];
    for (let i = 0; i < n; i++) {
      result.push(MockCommentFactory.newStoreComment());
    }
    return result;
  }

  static newUserComment(overrides?: Partial<UserComment>): UserComment {
    const chance = new Chance();
    return {
      commentId: overrides?.commentId ?? chance.guid(),
      username: overrides?.username ?? `${chance.first()} ${chance.last()[0]}.`,
      userId: overrides?.userId ?? chance.guid(),
      rating:
        overrides?.rating ?? (chance.integer({ min: 0, max: 5 }) as Ratings),
      comment: overrides?.comment ?? chance.paragraph(),
      date: overrides?.date ?? chance.date(),
      store: overrides?.store ?? MockStoreFactory.newStore(),
    };
  }

  static getUserComments(n: number): UserComment[] {
    const result = [];
    for (let i = 0; i < n; i++) {
      result.push(MockCommentFactory.newUserComment());
    }
    return result;
  }
}
