import { computed, flow, observable } from "mobx";
import { Partner, PartnerDetail } from "../models/index";
import { getPartner, getPartnerDetails } from "../services/index";


export class PartnerRepository {
  @observable private _partner: Partner[] = [];
  @observable private _partnerDetail: PartnerDetail = {} as any;


  constructor(typename?: string) {
    this.fetchPartner();
  }

  fetchPartner = flow(function* (this: PartnerRepository) {
    try {
      const allPartner: Partner[] = yield getPartner("partner");
      this._partner = allPartner;

    } catch (error) {
      console.error(error);
    }
  }).bind(this);

  fetchPartnerDetail = flow(function* (this: PartnerRepository, partnerId: string) {
    try {
      const details: PartnerDetail = yield getPartnerDetails(partnerId);
      this._partnerDetail = details;

    } catch (error) {
      console.error(error);
    }
  }).bind(this);

  @computed get partner(): Partner[] {
    return this._partner;
  }
  @computed get partnerDetail(): PartnerDetail {
    return this._partnerDetail;
  }
}
