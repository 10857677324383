import * as Yup from "yup";
import i18next from "i18next";
import { PersonTitle } from "app/users/models";
import { useRootRepositoryContext } from "../../../rootRepository";

export interface IUserLoginResponseSchema {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
}

export interface IUserLoginSchema {
  username: string;
  password: string;
  grant_type: string;
}

export const CreateUserFormSchema = Yup.object({
  title: Yup.mixed<PersonTitle>().optional(),
  username: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .required(i18next.t(`users:form.validation.requiredUsername`)),
  firstName: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .required(i18next.t(`users:form.validation.requiredFirstname`)),
  lastName: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .required(i18next.t(`users:form.validation.requiredLastname`)),
  email: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .email(i18next.t(`users:form.validation.mail`))
    .required(i18next.t(`users:form.validation.requiredEmail`)),
  phoneNumber: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(24, i18next.t(`users:form.validation.max`)).optional(),
  password: Yup.string()
    .min(6, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .required(i18next.t(`users:form.validation.requiredPassword`)),
  repeatPassword: Yup.string()
    .min(6, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .required(i18next.t(`users:form.validation.requiredPassword`))
    .oneOf([Yup.ref("password"), null], i18next.t(`users:form.validation.passwordsMustMatch`)),
  createStore: Yup.boolean(),
  acceptTermsAndConditions: Yup.boolean()
    .required(i18next.t(`users:form.validation.required`))
    .test("userAcceptedTerms", "userMustAcceptTerms", (value) => value),
}).defined();

export type CreateUserFormViewModel = Yup.InferType<
  typeof CreateUserFormSchema
>;

export const EditUserFormSchema = Yup.object({
  title: Yup.mixed<PersonTitle>().optional(),
  username: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .required(i18next.t(`users:form.validation.required`)),
  firstName: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .required(i18next.t(`users:form.validation.required`)),
  lastName: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .required(i18next.t(`users:form.validation.required`)),
  email: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .email(i18next.t(`users:form.validation.mail`))
    .required(i18next.t(`users:form.validation.required`)),
  phoneNumber: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(24, i18next.t(`users:form.validation.max`))
}).defined();

export type EditUserFormViewModel = Yup.InferType<typeof EditUserFormSchema>;

export const ResetUserPasswordFormSchema = Yup.object({
  oldpassword: Yup.string()
    .min(6, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .required(i18next.t(`users:form.validation.required`)),
  password: Yup.string()
    .min(6, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .required(i18next.t(`users:form.validation.required`)),
  repeatPassword: Yup.string()
    .required(i18next.t(`users:form.validation.required`))
    .test("repeatPwTester", i18next.t(`users:form.validation.passwordsMustMatch`), function test(
      value?: string
    ) {
      return value === this.parent.password;
    }),
}).defined();

export type ResetUserPasswordFormViewModel = Yup.InferType<typeof ResetUserPasswordFormSchema>;

export const ResetUserPasswordPublicFormSchema = Yup.object({
  password: Yup.string()
    .min(6, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .required(i18next.t(`users:form.validation.required`)),
  repeatPassword: Yup.string()
    .required(i18next.t(`users:form.validation.required`))
    .test("repeatPwTester", i18next.t(`users:form.validation.passwordsMustMatch`), function test(
      value?: string
    ) {
      return value === this.parent.password;
    }),
}).defined();

export type ResetUserPasswordPublicFormViewModel = Yup.InferType<typeof ResetUserPasswordPublicFormSchema>;

export const RequestResetUserPasswordFormSchema = Yup.object({
  email: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .email(i18next.t(`users:form.validation.mail`))
    .required(i18next.t(`users:form.validation.required`))
}).defined();

export type RequestResetUserPasswordFormViewModel = Yup.InferType<typeof RequestResetUserPasswordFormSchema>;

export const PulbicUserContactFormSchema = Yup.object({
  subject: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .required(i18next.t(`users:form.validation.required`)),
  text: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(5000, i18next.t(`users:form.validation.max`))
    .required(i18next.t(`users:form.validation.required`)),
  email: Yup.string()
    .min(3, i18next.t(`users:form.validation.min`))
    .max(255, i18next.t(`users:form.validation.max`))
    .email(i18next.t(`users:form.validation.mail`))
    .required(i18next.t(`users:form.validation.required`))
}).defined();

export type PublicUserContactFormViewModel = Yup.InferType<typeof PulbicUserContactFormSchema>;
