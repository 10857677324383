import React, { useContext } from "react";
import StoreRepository from "./StoreRepository";

interface RepositoryContextProps {
  storeRepository: StoreRepository;
}

export const StoreRepositoryContext = React.createContext<
  RepositoryContextProps
>({} as RepositoryContextProps);

export const useStoreRepositoryContext = () =>
  useContext(StoreRepositoryContext);
