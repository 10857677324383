import { Text, Link } from "@chakra-ui/react";
import {
  ContentArea,
  Headline,
} from "app/shared";
import { StaticPageTextBlock } from "app/staticPages/StaticPageTextBlock";

import { PartnerRepositoryProvider } from "app/partner/repositories";
import { PartnerResult } from "app/partner/partnerResult";
import React from "react";
import i18next from "i18next";
import useScript from "../utils/useScript";



export const Partner: React.FC = () => {

  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'partner', section: 'partner', pubdate: '', author: '', url: '/partner', object_type: 'partner' }}); } ");

  return (

    <ContentArea>
      <Headline as="h1">{i18next.t("partner:partner.headline")}</Headline>
      <StaticPageTextBlock mb={12}>
        <Text fontWeight="bold">
          {i18next.t("partner:partner.introText")}
        </Text>
      </StaticPageTextBlock>
      <PartnerRepositoryProvider >
        <PartnerResult></PartnerResult>
      </PartnerRepositoryProvider>

    </ContentArea>
  )
};
