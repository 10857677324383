import { BoxProps, Box, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { CategorySearchContext } from "./context";

type MetaCategory = string;

interface Props extends BoxProps {
  category: MetaCategory;
}

export const MetaCategory: React.FC<Props> = ({ category, ...rest }) => {
  const { selectedMetaCategories, setSelectedMetaCategories } = useContext(
    CategorySearchContext
  );
  const selected = selectedMetaCategories?.includes(category);
  const newCategories = selected
    ? selectedMetaCategories?.filter((name) => name !== category)
    : [...(selectedMetaCategories as MetaCategory[]), category];
  return (
    <Box
      bg={selected ? "brandRed.300" : "#F2F2F2"}
      onClick={() => {
          if (newCategories !== undefined) {
            newCategories.length = 0;
            if (!selected) {
              newCategories.push(category);
            }
          }
          setSelectedMetaCategories?.(newCategories ?? []);
        }
      }
      py={1}
      my={2}
      _hover={{ cursor: "pointer" }}
      {...rest}>
      <Text
        textAlign="center"
        fontSize={["2xs", "md"]}
        fontWeight="bold"
        color={selected ? "white" : "brandRed.300"}
        isTruncated>
        {category}
      </Text>
    </Box>
  );
};
