import { Box, BoxProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface Props extends BoxProps {
  children?: ReactNode;
}
export const StaticPageTextBlock: React.FC<Props> = ({ children, ...rest }) => (
  <Box fontSize="lg" color="darkGrey" {...rest}>
    {children}
  </Box>
);
