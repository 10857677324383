/* eslint-disable @typescript-eslint/no-explicit-any */
import environment from "configurations";
import GoogleMapReact, {
  ChangeEventValue,
  ClickEventValue,
} from "google-map-react";

import React, { ReactNode, useState } from "react";
import { Coords, DEFAULT_CENTER } from "./models";

import { StoreMarker } from "app/search/searchResults/StoreMarker";
import MarkerClusterer from '@googlemaps/markerclustererplus';
import ReactDOM from "react-dom";
import iconImage from 'styles/img/mapIcon.png';



interface Props {
  children?: ReactNode;
  center?: Coords;
  zoom?: number;
  minZoom?: number;
  maxZoom?: number;
  onClick?: (value: ClickEventValue) => void;
  onChildClick?: (hoverKey: any, childProps: any) => void;
  onChange?: (value: ChangeEventValue) => void;
  onGoogleApiLoaded?: (maps: any) => void; // callback proprietary to google api
  storeMarker?: StoreMarker[];
}

/**
 * Google maps returns coordinates outside [85, 180] if the noWrap method is not used:
 * https://developers.google.com/maps/documentation/javascript/reference/coordinates
 * Each time there is a change in map center we create a new location inside [85, 180]
 * before passing it to the component consumer.
 */
export const Map: React.FC<Props> = ({
  children,
  center,
  zoom,
  minZoom,
  maxZoom,
  onClick,
  onChildClick,
  onChange,
  onGoogleApiLoaded,
  storeMarker,
}) => {
  const [googleMapsApi, setGoogleMapsApi] = useState<any>(undefined);
  const wrapCoordinate = (coord: Coords): Coords => {
    const newCoord = new googleMapsApi.LatLng(coord.lat, coord.lng, false);
    return {
      lat: newCoord.lat(),
      lng: newCoord.lng(),
    };
  };

  // rekursives Heraussuchen des geklickten "currentTargets" aus
  // dem Click-Event-Objekts
  function getCurrentTarget(obj: any): any {
    let currentTarget = null;
    if (obj["currentTarget"]) {
      currentTarget = obj["currentTarget"];
    }
    else {
      for (let key in obj) {
        if (currentTarget) {
          break;
        }
        currentTarget = getCurrentTarget(obj[key]);
      }
    }

    return currentTarget;
  }


  function setGoogleMapRef(map: any, maps: any, storemarkers?: StoreMarker[]) {
    let googleMapRef = map
    let googleRef = maps

    let markers = storemarkers && storemarkers.map((storemarker) => {
      let location = { lat: +storemarker.props.lat, lng: +storemarker.props.lng };
      const marker = new googleRef.Marker({
        position: location,
        icon: storemarker.props.isHoveredOnList ? "" : iconImage,
        data: storemarker,

        //visible: true
      })

      marker.addListener("click", (l: any) => {
        // liefert das geklickte Element
        let currentTarget = getCurrentTarget(l);
        let clickedElement = currentTarget;
        // in der onCLickCustom werden die left- und top-Koordinaten des Eltern-Elements gesucht.
        // Da wir aber hier schon das zutreffende Element gefunden haben, übergeben wir
        // das Kinder-Element(in dem Fall das Img vom Marker)
        let childElement = clickedElement?.children[0] ?? null;
        storemarker.props.onClickCustom(childElement as Element, storemarker.props.store);
        console.log(storemarker.props);
      });

      return marker;
    })
    let markerCluster = new MarkerClusterer(map, markers, {
      imagePath: 'https://wir-liefern.org/images/m',
      gridSize: 50,
      minimumClusterSize: 10,
      //styles: [{
      //  url: "https://googlemaps.github.io/js-markerclustererplus/images/m1.png",
      //  width: 53,
      //  height: 53,        

      //}]
    })

  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: environment.googleMapsKey,
        libraries: ["places"],
      }}
      key={storeMarker ? storeMarker.length : "mapKey_0123"}
      center={center}
      zoom={zoom}
      options={{
        minZoom,
        maxZoom,
        fullscreenControl: false,
      }}
      onClick={onClick}
      onChildClick={onChildClick}
      onChange={(event) => {

        if (googleMapsApi) {
          onChange?.({
            ...event,
            center: wrapCoordinate(event.center),
            bounds: {
              nw: wrapCoordinate(event.bounds.nw),
              se: wrapCoordinate(event.bounds.se),
              sw: wrapCoordinate(event.bounds.sw),
              ne: wrapCoordinate(event.bounds.ne),
            },
            marginBounds: {
              nw: wrapCoordinate(event.marginBounds.nw),
              se: wrapCoordinate(event.marginBounds.se),
              sw: wrapCoordinate(event.marginBounds.sw),
              ne: wrapCoordinate(event.marginBounds.ne),
            },
          });
        } else {
          onChange?.(event);
        }
      }}
      yesIWantToUseGoogleMapApiInternals={true}
      onGoogleApiLoaded={({ map, maps }) => {
        setGoogleMapsApi(maps);
        onGoogleApiLoaded?.(maps);
        setGoogleMapRef(map, maps, storeMarker)
      }}
    >
      {children}
    </GoogleMapReact>)
};

Map.defaultProps = {
  center: DEFAULT_CENTER,
  zoom: 15,
  minZoom: 3,
  maxZoom: 15,
};

