import React, { useContext } from "react";
import { SearchRepository } from "./SearchRepository";

interface RepositoryContextProps {
  searchRepository: SearchRepository;
}

export const SearchRepositoryContext = React.createContext<
  RepositoryContextProps
>({} as RepositoryContextProps);

export const useSearchRepositoryContext = () =>
  useContext(SearchRepositoryContext);
