import { Box, BoxProps, Stack, Text } from "@chakra-ui/react";
import { Rating } from "app/shared";
import React from "react";
import { StoreComment } from "../models";

interface Props extends BoxProps {
  comment: StoreComment;
  ratingActiveColor?: string;
  ratingInactiveColor?: string;
  commentMaxHeight?: string;
}

export const CommentView: React.FC<Props> = ({
  comment,
  ratingActiveColor,
  ratingInactiveColor,
  commentMaxHeight,
  ...rest
}) => {
  const commandDate = new Date(comment.date);
  const commandDateString = (commandDate.getDate() < 10 ? "0" + commandDate.getDate() : commandDate.getDate()) +
    "." +
    (commandDate.getMonth() + 1 < 10 ? "0" + (commandDate.getMonth() + 1) : (commandDate.getMonth() + 1 )) +
    "." +
    commandDate.getFullYear();


  return (
    <Stack direction="column" spacing={2}>
      <Text fontSize="sm">{commandDateString}</Text>
      <Text fontSize="xl" fontWeight="bold">
        {comment.username}
      </Text>
      <Rating
        rating={comment.rating}
        activeColor={ratingActiveColor ?? "white"}
        inactiveColor={ratingInactiveColor ?? "darkestGrey"}
      />
      <Box overflowY="auto" maxHeight={commentMaxHeight}>
        {comment.comment}
      </Box>
    </Stack>
  )
};
