export const MAX_SLIDER_VALUE = 100;
export const SLIDER_DEFAULT_VALUE = 10;

export type SortValue = "alphabetic" | "distance" | "rating";

interface SortOption {
  value: SortValue;
  i18n: string;
}

export const SortOptions: SortOption[] = [
  {
    value: "distance",
    i18n: "search:searchHeader.sort.distance",
  },
  {
    value: "alphabetic",
    i18n: "search:searchHeader.sort.alphabetic",
  },
  {
    value: "rating",
    i18n: "search:searchHeader.sort.rating",
  },
];
