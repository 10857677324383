import { BoxProps, Stack } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React from "react";
import { compose } from "recompose";
import { useRootRepositoryContext } from "rootRepository";
import { CategoryCard } from "./CategoryCard";
import { useSearchRepositoryContext } from "../../search/repositories";

interface Props extends BoxProps {
  onCategorySelected: (category: string, oldCatValue?: string, index?: number) => void;
  onSubCategorySelected: (subCategories: string[]) => void;
  autoSelectSubCategories: boolean;
  onCategorySelectForDropdown?: (category: string) => void;
  index: number
}

const CategoryCarouselBase: React.FC<Props> = ({
  index,
  onCategorySelected,
  onSubCategorySelected,
  autoSelectSubCategories,
  onCategorySelectForDropdown
}) => {
  const { categoryRepository } = useRootRepositoryContext();
  const { searchRepository } = useSearchRepositoryContext();
  //to use after fixing icons size
  // const findIconForCategory = (category: string): string =>
  //   CategoryIcons.find((c) => c.categoryName === category)?.iconName ?? "shirt";

  const handleOnCategorySelected = (categoryId: string) => {
    const subCategoriesIds: string[] = autoSelectSubCategories
      ? categoryRepository.categories
        .find((c) => c.id === categoryId)
          ?.subCategories.map((subCategory) => subCategory.id) ?? []
      : [];

    onCategorySelected(categoryId, "", index);

    if (onCategorySelectForDropdown) {
      onCategorySelectForDropdown(categoryId);
    }

    if (subCategoriesIds.length > 0) {
      // Abfrage auf Länge der ausgewählten Subcategorien. Wenn bei der Multi-Auswahl
      // beim Store anlegen eine neue Kategorie gewählt wird, dann ist das Array leer. Wenn
      // das leere Array hier übernommen werden würde, dann verlieren alle anderen Picker ihre Auswahl
      onSubCategorySelected(subCategoriesIds);
    }
    searchRepository?.setCurrentPage(1);
    searchRepository?.fetchStoresForPage();
  };

  return (
    <Stack h="140px" direction="row" overflowX="scroll" spacing={1} >
      {categoryRepository.categories.sort((a, b) => a.categoryName.localeCompare(b.categoryName)).map((category) => (
        <CategoryCard
          key={`category_${category.categoryName}`}
          category={category.categoryName}
          onClick={() => handleOnCategorySelected(category.id)}
        />
      ))}
    </Stack>
  );
};

export const CategoryCarousel = compose<Props, Props>(observer)(
  CategoryCarouselBase
);
