import React from "react";
import {
  GenericInput,
  DEFAULT_LABEL_WIDTH,
  FieldInternalProps,
} from "app/shared/forms/GenericInput";
import { GenericRadioGroup } from "app/shared/forms/GenericRadioGroup";
import { GenericSelect } from "app/shared/forms/GenericSelect";
import { Flex, Box, FormLabel, FormControl } from "@chakra-ui/react";
import i18next from "i18next";
import { Field } from "formik";
import { StyledCheckbox } from "app/shared";

export interface FieldProps {
  fieldName: string;
  fieldType: string;
  Component: any;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidthPx?: string;
  flexDirection?: string;
}

interface RadioGroupProps {
  fieldName: string;
  fieldType: string;
  options: boolean[];
  disabled?: boolean;
  defaultChecked?: boolean;
}

interface SelectProps {
  fieldName: string;
  fieldType: string;
  options: string[];
  disabled?: boolean;
  flexDirection?: string;

}

export const UserFormField: React.FC<FieldProps> = (props: FieldProps) => (
  <GenericInput i18nextPrefix="users" {...props} />
);

export const UserRadioGroupField: React.FC<RadioGroupProps> = (
  props: RadioGroupProps
) => <GenericRadioGroup i18nextPrefix="users" {...props} />;

export const UserSelectField: React.FC<SelectProps> = (props: SelectProps) => (
  <GenericSelect i18nextPrefix="users" {...props} />
);

interface SingleSelectionRadioProps {
  fieldName: string;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidthPx?: string;
}

export const SingleSelectionRadio = (props: SingleSelectionRadioProps) => {
  const { fieldName } = props;
  const i18nextPrefix = "users";
  const disabled = props.disabled ?? false;
  const hideLabel = props.hideLabel ?? false;
  const labelWidthPx = props.labelWidthPx || DEFAULT_LABEL_WIDTH;
  return (
    <Flex mt={3}>
      <Box width={labelWidthPx}>
        {!hideLabel && (
          <FormLabel>
            {i18next.t(`${i18nextPrefix}:form.fields.${fieldName}.label`)}
          </FormLabel>
        )}
      </Box>
      <Box flex="1">
        <Field className="block" name={fieldName} type={"boolean"}>
          {(renderProps: FieldInternalProps) => (
            <FormControl
              isInvalid={
                renderProps.form.errors[fieldName] &&
                renderProps.form.touched[fieldName]
              }>
              <Flex mt={3}>
                <StyledCheckbox
                  id={fieldName}
                  isDisabled={disabled}
                  fontSize="lg"
                  px={3}
                  py={2}
                  onChange={(e) => {
                    renderProps.form.setFieldValue(fieldName, e.target.checked);
                    renderProps.form.setFieldTouched(fieldName, true);
                  }}
                />
              </Flex>
            </FormControl>
          )}
        </Field>
      </Box>
    </Flex>
  );
};
