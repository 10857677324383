import { Text, Link } from "@chakra-ui/react";
import {
  ContentArea,
  Headline,
} from "app/shared";
import { StaticPageTextBlock } from "app/staticPages/StaticPageTextBlock";

import { PresseRepositoryProvider } from "app/presse/repositories";
import { PresseResult } from "app/presse/newsResult";
import React from "react";
import i18next from "i18next";
import useScript from "../utils/useScript";



export const Presse: React.FC = () => {

  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'presse', section: 'presse', pubdate: '', author: '', url: '/presse', object_type: 'presse' }}); } ");

  return (

    <ContentArea>
      <Headline as="h1">{i18next.t("presse:presse.headline")}</Headline>
      <StaticPageTextBlock mb={12}>

      </StaticPageTextBlock>
      <PresseRepositoryProvider >
        <PresseResult></PresseResult>
      </PresseRepositoryProvider>

    </ContentArea>
  )
};
