import { computed, flow, observable } from "mobx";
import { Product } from "app/products/models";
import { getProduct } from "../services/index";

type Status = "successful" | "pending" | "error";

export default class ProductRepository {
  @observable private _product: Product = {} as any;
  @observable private _status: Status = "pending";

  fetchProduct = flow(function* (this: ProductRepository, productId: string) {
    try {
      const product: Product = yield getProduct(productId);

      this._status = "successful";
      this._product = product;
    } catch (error) {
      this._status = "error";
    }
  }).bind(this);

  @computed get product(): Product {
    return this._product;
  }

  @computed get status(): Status {
    return this._status;
  }
}
