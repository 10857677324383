import { ContentArea } from "app/shared";
import { Text } from "@chakra-ui/react";
import React from "react";
import CreateStoreForm from "app/stores/forms/CreateStoreForm";
import { compose } from "recompose";
import { observer } from "mobx-react";
import { useRootRepositoryContext } from "rootRepository";
import i18next from "i18next";
import useScript from "../utils/useScript";

const CreateStorePageBase: React.FC = () => {
  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'newStore', section: 'newStore', pubdate: '', author: '', url: '/newStore', object_type: 'newStore' }}); } ");

  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  return (
    <ContentArea>
      {user ? (
        <CreateStoreForm />
      ) : (
        <Text>{i18next.t("shared:loginRequired")}</Text>
      )}
    </ContentArea>
  );
};

export default compose<{}, {}>(observer)(CreateStorePageBase);
