import { StoreId } from "app/stores/models";
import {
  PaginatedComments,
  PaginatedUserComments,
  StoreComment,
} from "app/comments/models";
import { MockCommentFactory } from "./MockCommentFactory";
import { UserId } from "app/users/models";

export class MockCommentBackendService {
  static async createStoreComment() {
    return new Promise<StoreComment>((resolve, reject) => {
      setTimeout(() => {
        resolve(MockCommentFactory.newStoreComment());
      }, 500);
    });
  }

  static async createMockedComments(storeId: StoreId) {
    return new Promise<PaginatedComments>((resolve, reject) => {
      const paginatedComments: PaginatedComments = {
        totalResults: 8,
        currentPage: 1,
        perPage: 4,
        nextPageUrl: "nextPageUrl",
        prevPageUrl: "prevPageUrl",
        urlTemplate:
          "https://api.wirliefern.de/store/1234/comments?perPage=10&currentPage={}",
        data: MockCommentFactory.getStoreComments(4),
      };

      setTimeout(() => {
        resolve(paginatedComments);
      }, 500);
    });
  }

  static async createMockedMoreComments(storeId: StoreId) {
    return new Promise<PaginatedComments>((resolve, reject) => {
      const paginatedComments: PaginatedComments = {
        totalResults: 8,
        currentPage: 2,
        perPage: 4,
        nextPageUrl: "",
        prevPageUrl: "prevPageUrl",
        urlTemplate:
          "https://api.wirliefern.de/store/1234/comments?perPage=10&currentPage={}",
        data: MockCommentFactory.getStoreComments(4),
      };

      setTimeout(() => {
        resolve(paginatedComments);
      }, 500);
    });
  }

  static async createMockedPaginatedUserComments(userId: UserId) {
    return new Promise<PaginatedUserComments>((resolve, reject) => {
      const paginatedUserComments: PaginatedUserComments = {
        totalResults: 1,
        currentPage: 1,
        perPage: 2,
        nextPageUrl: "nextPageUrl",
        prevPageUrl: "prevPageUrl",
        urlTemplate:
          "https://api.wirliefern.de/user/1234/comments?perPage=10&currentPage={}",
        data: MockCommentFactory.getUserComments(6),
      };

      setTimeout(() => {
        resolve(paginatedUserComments);
      }, 500);
    });
  }
}
