import React, { useContext } from "react";
import CommentRepository from "./CommentRepository";

interface RepositoryContextProps {
  commentRepository: CommentRepository;
}

export const CommentRepositoryContext = React.createContext<
  RepositoryContextProps
>({} as RepositoryContextProps);

export const useCommentRepositoryContext = () =>
  useContext(CommentRepositoryContext);
