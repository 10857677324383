import axios from "network";
import { StoreId } from "../../stores/models";
import { PublicUserContactFormViewModel } from "../../users/forms/UserFormSchema";

export const sendContactMail = async (
  storeId: StoreId,
  subject: string,
  text: string,
): Promise<boolean> => {
  return await new Promise((resolve, reject) => {
    axios
      .post(`/Contact/SendContactMailToStore`, {
        StoreId: storeId,
        Text: text,
        Subject: subject
      })
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response.data.Code == 999) {
          reject(response.data.Message);
        }
        else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

export const sendPublicContactMail = async (
  values: PublicUserContactFormViewModel
): Promise<boolean> => {
  return await new Promise((resolve, reject) => {
    axios
      .post(`/Contact/SendContactMailToSupport`, {
        Mail: values.email,
        Subject: values.subject,
        Text: values.text
      })
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response.data.Code == 999) {
          reject(response.data.Message);
        }
        else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

export const sendReportMail = async (
  storeId: StoreId,
  text: string,
): Promise<boolean> => {
  return await new Promise((resolve, reject) => {
    axios
      .post(`/Contact/SendStoreReportMailToSupport`, {
        StoreId: storeId,
        Text: text,
        Subject: ""
      })
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response.data.Code == 999) {
          reject(response.data.Message);
        }
        else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};
