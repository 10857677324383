import { ContentArea } from "app/shared";
import React from "react";
import { CreateUserForm } from "app/users/forms/CreateUserForm";
import useScript from "../utils/useScript";

const CreateUserPage: React.FC = () => {
  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'newUser', section: 'newUser', pubdate: '', author: '', url: '/newUser', object_type: 'newUser' }}); } ");

  return (
    <ContentArea>
      <CreateUserForm />
    </ContentArea>
  )
};

export default CreateUserPage;
