import { Box, Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { compose } from "recompose";
//import { NewsCard } from "./NewsCard";
import { Testimonial } from "../models/index";
//import { NewsRepository } from "../repositories/NewsRepository";
import { useRootRepositoryContext } from "rootRepository";
import { useMediaQueryContext } from "styles/context";
import { TestimonialCard } from "./TestimonialCard";

interface Props {

}

const TestimonialResultsBase: React.FC<Props> = () => {
  const { testimonialRepository } = useRootRepositoryContext();
  const { isSmall, isSmallMedium } = useMediaQueryContext();

  const [testimonials, setTestimonials] = useState([] as Testimonial[]);

  useEffect(() => {
    testimonialRepository.fetchActiveTestimonials().then(() => {
      setTestimonials(testimonialRepository.activeTestimonials);
    });
  }, []);

  return (
    <Box>

      <Flex
        direction={[
          "column",
        ]}
        justifyContent="space-between">
        {
          testimonials.length > 0 && (
            testimonials.map((testimonial) => (
              <TestimonialCard
                key={testimonial.id}
                headline={testimonial.headline}
                id={testimonial.fk_stores}
                videoUrl={testimonial.linkVideo}
                videoPosition="left"
                text={testimonial.text}
                mb={"40px"}
              ></TestimonialCard>
            ))
          )
        }


      </Flex>
    </Box>
  );
};

export const TestimonialResult = compose<Props, Props>(observer)(TestimonialResultsBase);

