import { Box, BoxProps, Flex, Stack, Text, Image } from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { ButtonLink } from "app/shared/buttonLink";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { compose } from "recompose";
import { usePartnerRepositoryContext } from "../repositories";
import { Button, Headline } from "app/shared";
import { SitePager } from "app/shared/sitePager";
import { useMediaQueryContext } from "styles/context";

import { PartnerDetail } from "../models/index";

interface Props extends BoxProps {
  partnerId: string;
}

const PartnerDetailsInfoBase: React.FC<Props> = ({ partnerId }) => {

  const { isMediumLarge, isSmallMedium } = useMediaQueryContext();
  const { partnerRepository } = usePartnerRepositoryContext();
  const [loaded, setLoaded] = useState<boolean>(false);

  if (!loaded) {
    partnerRepository.fetchPartnerDetail(partnerId).then(() => {
      setLoaded(true);
    });
  }

  let partnerText = "";
  if (loaded) {
    //let tmp = document.createElement("DIV");
    //tmp.innerHTML = newsRepository.newsDetail?.text;
    //htmlText = tmp.textContent || tmp.innerText || "";

    partnerText = partnerRepository.partnerDetail.profile;
    partnerText = partnerText?.replace("<a", "<a style='color: #EB5757; font-weight: 700;' ").replace("<h2", "<h2 style='font-weight: bold;' ");
  }

  return (
    loaded ? (
      <Box>
        <Flex >
          <Box width={isMediumLarge ? "100%" : "910px"}>
            <Headline as="h1">{partnerRepository.partnerDetail?.partner}</Headline>
            <Text fontWeight="bold" fontSize="20px" mb="20px" color="darkGrey">
              <span dangerouslySetInnerHTML={{ __html: partnerRepository.partnerDetail?.profileShort }} /></Text>
            <Stack direction="row" mb="20px" spacing={8}>
              {partnerRepository.partnerDetail.imageURL && (<Box minWidth="200px" maxWidth="200px">
                <Image src={partnerRepository.partnerDetail.imageURL} width={"100"} height={"auto"} />
              </Box>)}
              <Box fontSize="lg" color="darkGrey">
                <Text>{partnerRepository.partnerDetail.partner}</Text>
                <Text>{partnerRepository.partnerDetail.street}</Text>
                <Text>{partnerRepository.partnerDetail.zipCode} {partnerRepository.partnerDetail.city}</Text>
                <Text>{partnerRepository.partnerDetail.telephone}</Text>
                <Text>{partnerRepository.partnerDetail.email}</Text>
                <Text>{partnerRepository.partnerDetail.website}</Text>
              </Box>
            </Stack>
            <Text fontWeight="400" fontSize="20px" color="darkGrey" lineHeight="27px"><span dangerouslySetInnerHTML={{ __html: partnerRepository.partnerDetail?.profile }} /></Text>
          </Box>
        </Flex>
        <Flex
          mt="25px"
        >
          <ButtonLink
            color="white"
            width="215px"
            height="40px"
            mr="14px"
            backgroundColor="brandRed.300"
            _hover={{ backgroundColor: "brandRed.400" }}
            to={"/partner"}>

            {"<< Zurück zur Übersicht"}

          </ButtonLink>
        </Flex>
      </Box >
    ) : (<></>)

  )
};

export const PartnerDetailsInfo = compose<Props, Props>(observer)(PartnerDetailsInfoBase);
