import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import { BottomTriangle, CloseButton } from "app/shared";
import React from "react";
import { CategoryIcons } from "../constants";
import { CategoryIcon } from "./CategoryIcon";

interface Props extends BoxProps {
  category: string;
  onRemove?: () => void;
}

export const CategoryCard: React.FC<Props> = ({
  category,
  onClick,
  onRemove,
  ...rest
}) => {
  const categoryIcon = CategoryIcons.find((c) => c.categoryName === category);

  return (
    <Box
      h="120px"
      _hover={onClick ? { cursor: "pointer" } : undefined}
      onClick={onClick}
      {...rest}>
      <Flex
        p={2}
        w="130px"
        h="83%"
        direction="column"
        alignItems="center"
        bg="brandRed.300"
        color="white"
        boxShadow="lg"
        position="relative">
        {onRemove && (
          <CloseButton
            onClick={onRemove}
            iconSize={"13px"}
            position="absolute"
            top={0}
            right={1}
          />
        )}
        <Box height="45px">
          <CategoryIcon
            name={categoryIcon ? categoryIcon.iconName : "shirt"}
            boxSize="40px"
          />
        </Box>
        <Text
          textAlign="center"
          fontSize="xxs"
          fontWeight="bold"
          wordBreak="break-word">
          {category}
        </Text>
        <BottomTriangle selectable={true} top={95} />
      </Flex>
    </Box>
  );
};
