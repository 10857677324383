import { BoxProps, Stack, Text, Link } from "@chakra-ui/react";
import React from "react";

interface Props extends BoxProps {
  icon: object;
  text: string;
  textColor: string;
  isLink?: boolean;
  isMailToLink?: boolean;
  isPhoneLink?: boolean;
  link?: string
}

export const IconWithText: React.FC<Props> = ({
  icon,
  text,
  textColor,
  color,
  isLink,
  isMailToLink,
  isPhoneLink,
  link,
  ...rest
}) => {

  const hrefUrl = link !== undefined ? link : text;

  return (
    <Stack direction="row" spacing={2} alignItems="center" {...rest}>
      {icon}

      {isLink && hrefUrl.length > 0 && text.length > 0 && (
        <Link fontSize="lg" color={textColor ?? color} href={hrefUrl.indexOf("http") > -1 ? hrefUrl : "https://" + hrefUrl} target="_blank">
          {text}
        </Link>)
      }
      {isMailToLink && (
        <Link fontSize="lg" color={textColor ?? color} href={"mailTo: " + hrefUrl}>
          {text}
        </Link>)
      }
      {isPhoneLink && (
        <Link fontSize="lg" color={textColor ?? color} href={"tel: " + hrefUrl}>
          {text}
        </Link>)
      }
      {(isLink === undefined || !isLink)
        && (isMailToLink === undefined || !isMailToLink)
        && (isPhoneLink === undefined || !isPhoneLink)
        && (
          <Text fontSize="lg" color={textColor ?? color}>
            {text}
          </Text>)
      }
    </Stack>
  )
};
