import { useEffect } from 'react';

const useScriptViaUrl = (url:string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

const useScriptViaString = (content: string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.async = true;
    script.text = content;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [content]);
};

export default { useScriptViaString, useScriptViaUrl };
