export interface Coords {
  lat: number;
  lng: number;
}

export const DEFAULT_CENTER: Coords = { lat: 30, lng: 0 };

/**
 * This value should be used to get the LatLngBound max distance from coordinate [0,0] to [0,180].
 * Useful to use in searches.
 */
export const MAX_DIAMETER_KM = 20016;

export const DEFAULT_BOUNDS = {
  nw: { lat: 85, lng: -180 },
  se: { lat: -85, lng: 180 },
  sw: { lat: -85, lng: -180 },
  ne: { lat: 85, lng: 180 }
};
