import { BoxProps, Box } from "@chakra-ui/react";
import React from "react";
import { FavoriteIcon, FavoriteOpaqueIcon } from "styles/icons";

interface Props extends BoxProps {
  isFavorite: boolean;
  iconSize?: string;
}

export const StoreFavorite: React.FC<Props> = ({
  isFavorite,
  onClick,
  width,
  iconSize,
}) => (
  <Box _hover={{ cursor: "pointer" }} onClick={onClick}>
    {isFavorite ? (
      <FavoriteIcon width={width} boxSize={iconSize} />
    ) : (
      <FavoriteOpaqueIcon width={width} boxSize={iconSize} />
    )}
  </Box>
);
