import { computed, flow, observable } from "mobx";
import { User } from "../models";
import { doLogin, getUser } from "../services";
import { IUserLoginSchema, IUserLoginResponseSchema } from "../forms/UserFormSchema";
import Cookies from "js-cookie";
import environment from "../../../configurations";

export class UserRepository {
  constructor() {
    var tokenString = Cookies.get(`${environment.cookieKey}`);
    if (tokenString != undefined) {
      const token: IUserLoginResponseSchema = JSON.parse(tokenString) as IUserLoginResponseSchema;

      if (token != null) {
        this.login(token);
      }
    }
  }

  @observable private _user: User | undefined = undefined;

  login = flow(function* (this: UserRepository, loginResponse: IUserLoginResponseSchema) {
    this._user = yield getUser(loginResponse);
  }).bind(this);
  
  update = function (this: UserRepository, user: User) {
    this._user = user;
  };
  
  updatePicture = function (this: UserRepository, profilePictureUrl: string) {
    if (this._user) {
      this._user.profilePictureUrl = profilePictureUrl;
    }
  };

  logout = flow(function* (this: UserRepository) {
    this._user = yield undefined;
    Cookies.remove(`${environment.cookieKey}`);
  }).bind(this);

  getToken = flow(function* (this: UserRepository, loginRequest: IUserLoginSchema) {
    return yield doLogin(loginRequest);
  }).bind(this);

  @computed get user(): User | undefined {
    return this._user;
  }
}
