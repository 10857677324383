import React from "react";

import { StoreWindowRepositoryContext } from "./context";
import StoreWindowRepository from "./StoreWindowRepository";

interface Props {
  children: React.ReactNode;
}

export const StoreWindowRepositoryProvider: React.FC<Props> = ({
  children,
}) => {
  const storeWindowRepository = new StoreWindowRepository();

  return (
    <StoreWindowRepositoryContext.Provider value={{ storeWindowRepository }}>
      {children}
    </StoreWindowRepositoryContext.Provider>
  );
};
