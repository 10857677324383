import { ContentArea } from "app/shared";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { PresseRepositoryProvider } from "app/presse/repositories";
import { PresseDetailsInfo } from 'app/presse/presseDetail';
import useScript from "../utils/useScript";
interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

const PresseDetailsBase: React.FC<Props> = ({ match }) => {
  const id = match.params.id;

  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: '" + id + "', section: 'presseDetail', pubdate: '', author: '', url: '/presseDetail', object_type: 'presseDetail' }}); } ");

  return (
    <ContentArea >
      <PresseRepositoryProvider >
        <PresseDetailsInfo newsId={id} ></PresseDetailsInfo>
      </PresseRepositoryProvider>
    </ContentArea>
  );
};

export const PresseDetails = compose<Props, {}>(withRouter)(PresseDetailsBase);
