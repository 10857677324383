import { Box, BoxProps, Image, Stack, Text, Link } from "@chakra-ui/react";
import { TextLink } from "app/shared/textLink";
import React, { ReactNode } from "react";
import { Headline, EmbeddedVideo } from "app/shared";
import { useMediaQueryContext } from "styles/context";

interface Props extends BoxProps {
  videoUrl: string;
  text: string;
  id: string;
  headline: string;
  videoPosition: "left" | "right";
}

export const TestimonialCard: React.FC<Props> = ({
  videoUrl,
  videoPosition,
  text,
  id,
  headline,
  ...rest
}) => {
  const { isSmall, isSmallMedium } = useMediaQueryContext();

  const flexDirection = isSmallMedium ? "column" : videoPosition === "left" ? "row" : "row-reverse";
  const shopLink = `store/${id}`;
  const withVideo = videoUrl != null && videoUrl.length > 0;

  return (
    <Stack direction={flexDirection} spacing={8} {...rest}>
      {withVideo && (
        <Box minWidth={isSmall ? "100%" : "390px"} maxWidth={isSmall ? "100%" : "390px"} mb={ "0px" }>
          <EmbeddedVideo
            src={videoUrl}
            mx="auto"
            title="embeddedVideo"
            mb={0}
          />
        </Box>)
      }
      <Box fontSize="lg" color="darkGrey" mt={isSmallMedium ?  "-35px" : "0"}>
        <Headline as="h2" mt="20px" mb="20px" fontSize={["xl", "xl", "5xl", "5xl"]} lineHeight={"1.1"}>{headline}</Headline>
        <Box>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </Box>
        <TextLink
          to={shopLink}
          color="brandRed.300"
          fontWeight="bold">
          {"Zum Lieblingsladen >>"}
        </TextLink>
      </Box>
    </Stack>
  );
};
