import { ContentArea } from "app/shared";
import React from "react";
import { EditStoreForm } from "app/stores/forms/EditStoreForm";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Stack, Text } from "@chakra-ui/react";
import { compose } from "recompose";
import { StoreRepositoryProvider } from "app/stores/repositories";
import { useRootRepositoryContext } from "rootRepository";
import i18next from "i18next";
import { User } from "app/users/models";
import useScript from "../utils/useScript";

interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

const EditStorePageBase: React.FC<Props> = ({ match }) => {

  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'editStore', section: 'editStore', pubdate: '', author: '', url: '/editStore', object_type: 'editStore' }}); } ");

  const id = match.params.id;
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  return (
    <ContentArea>
      {user ? (
        <StoreRepositoryProvider>
        <Stack spacing={20} direction="column">
          {/* REQUIRES ACCESS TO THE LOGGED IN USE: <ProfileInfo editDisabled={false} />*/}
          <EditStoreForm storeId={id} />
        </Stack>
        </StoreRepositoryProvider>) : (
          <Text>{i18next.t("shared:loginRequired")}</Text>
        )}
    </ContentArea>
  );
};

export default compose<Props, {}>(withRouter)(EditStorePageBase);
