import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import i18next from "i18next";
import { Stack, Text } from "@chakra-ui/react";
import { compose } from "recompose";
import { ContentArea } from "app/shared";
import { EditProductForm } from "app/products/forms/EditProductForm";
import { ProductRepositoryProvider } from "app/products/repositories";
import { useRootRepositoryContext } from "rootRepository";
import { User } from "app/users/models";
import useScript from "../utils/useScript";

interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

const EditProductPageBase: React.FC<Props> = ({ match }) => {
  useScript.useScriptViaString(
    "if (typeof upScore == 'function') { upScore({data: {object_id: 'editProduct', section: 'editProduct', pubdate: '', author: '', url: '/editProduct', object_type: 'editProduct' }}); } "
  );

  const id = match.params.id;
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  return (
    <ContentArea>
      {user ? (
        <ProductRepositoryProvider>
          <Stack spacing={20} direction="column">
            {/* REQUIRES ACCESS TO THE LOGGED IN USE: <ProfileInfo editDisabled={false} />*/}
            <EditProductForm productId={id} />
          </Stack>
        </ProductRepositoryProvider>
      ) : (
        <Text>{i18next.t("shared:loginRequired")}</Text>
      )}
    </ContentArea>
  );
};

export default compose<Props, {}>(withRouter)(EditProductPageBase);
