import { StoreId } from "app/stores/models";
import { computed, flow, observable } from "mobx";
import { PaginatedComments, StoreComment } from "../models";
import { getCommentsForStore, getMoreComments } from "../services";

type Status = "successful" | "pending" | "error";

export default class CommentRepository {
  @observable private _paginatedComments: PaginatedComments = {} as any;
  @observable private _status: Status = "pending";
  @observable private _comments: StoreComment[] = [];

  // TODO: Temporary function while api isn't integrated
  fetchCommentsForPage = flow(function* (
    this: CommentRepository,
    storeId: StoreId
  ) {
    try {
      const paginatedComments: PaginatedComments = yield getCommentsForStore(
        storeId
      );

      this._status = "successful";
      this._paginatedComments = paginatedComments;
      this._comments = paginatedComments.data;
    } catch (error) {
      this._status = "error";
    }
  }).bind(this);

  fetchMoreComments = flow(function* (
    this: CommentRepository,
    storeId: StoreId
  ) {
    try {
      const paginatedComments: PaginatedComments = yield getMoreComments(
        storeId
      );
      this._paginatedComments = paginatedComments;
      this._comments = [...this._comments, ...paginatedComments.data];
    } catch (error) {
      this._status = "error";
    }
  }).bind(this);

  @computed get paginatedComments(): PaginatedComments {
    return this._paginatedComments;
  }

  @computed get status(): Status {
    return this._status;
  }

  @computed get comments(): StoreComment[] {
    return this._comments;
  }
}
