import React, { ReactNode } from "react";
import { Text, BoxProps } from "@chakra-ui/react";

interface Props extends BoxProps {
  children?: ReactNode;
}

export const StaticPageTitle: React.FC<Props> = ({ children }) => (
  <Text fontSize="7xl" as="h1" fontWeight="bold" color="darkGrey">
    {children}
  </Text>
);
