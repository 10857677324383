import { computed, flow, observable } from "mobx";
import { StoreWindow } from "../models";
import { getStoreWindow } from "../services/index";

type Status = "successful" | "pending" | "error";

export default class StoreWindowRepository {
  @observable private _storeWindow: StoreWindow = {} as any;
  @observable private _status: Status = "pending";

  fetchStoreWindow = flow(function* (
    this: StoreWindowRepository,
    storeWindowId: string
  ) {
    try {
      const storeWindow: StoreWindow = yield getStoreWindow(storeWindowId);

      this._status = "successful";
      this._storeWindow = storeWindow;
    } catch (error) {
      this._status = "error";
    }
  }).bind(this);

  @computed get storeWindow(): StoreWindow {
    return this._storeWindow;
  }

  @computed get status(): Status {
    return this._status;
  }
}
