import { BoxProps, Flex, Box } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface Props extends BoxProps {
  lat: number;
  lng: number;
  children: ReactNode;
  hoverEnabled?: boolean;
}

export const Marker: React.FC<Props> = ({
  children,
  hoverEnabled,
  ...rest
}) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    color="white"
      backgroundColor="brandRed.300"
   
    {...(rest as any)}>
    <Box _hover={hoverEnabled ? { cursor: "pointer" } : undefined}>
      {children}
    </Box>
  </Flex>
);

/*
 *  px={4}
      py={1}
backgroundColor="brandRed.300"
*/
