import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { Button, StyledInput } from "app/shared";
import { Form, Formik, FormikProps } from "formik";
import i18next from "i18next";
import React from "react";
import { User } from "../models";
import { UserFormField, UserSelectField } from "./UserFormItems";
import { PersonTitle, personTitleArray } from "app/users/models";
import { EditUserFormSchema, EditUserFormViewModel } from "./UserFormSchema";
import { editUser } from "../services";

interface SubmitHandlerProps {
  setSubmitting: (isSubmitting: boolean) => void;
}

interface Props {
  user: User;
  onSave: (response : User) => void;
}

export const EditUserForm: React.FC<Props> = ({ user, onSave }) => {  
  const submitHandler = (
    values: EditUserFormViewModel,
    handlerProps: SubmitHandlerProps
  ) => {
    editUser(values)
      .then((r) => {        
        onSave(r);
      })
      .catch((r) => alert(r))
      .finally(() => handlerProps.setSubmitting(false));
  };

  const initialState: EditUserFormViewModel = {
    title: user.title,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber,
    username: user.username,
  };

  const renderActualForm = (props: FormikProps<EditUserFormViewModel>) => {
    const { handleSubmit } = props;

    return (
      <Form onSubmit={handleSubmit}>
        <SimpleGrid columns={2}>
          <Box>
            <UserSelectField
              fieldName="title"
              fieldType="PersonTitle"
              options={personTitleArray.values}
            />

            <UserFormField
              fieldName="firstName"
              fieldType="string"
              Component={StyledInput}
            />
            <UserFormField
              fieldName="lastName"
              fieldType="string"
              Component={StyledInput}
            />
            <UserFormField
              fieldName="email"
              fieldType="string"
              disabled={true}
              Component={StyledInput}
            />
            <UserFormField
              fieldName="username"
              disabled={true}
              fieldType="string"
              Component={StyledInput}
            />
            <UserFormField
              fieldName="phoneNumber"
              fieldType="string"
              Component={StyledInput}
            />
          </Box>
        </SimpleGrid>
        <Flex py={3} justifyContent="center">
          <Button
            width="200px"
            type="submit"
            isDisabled={!props.isValid || props.isSubmitting}>
            {i18next.t(`users:form.submit.labelSave`)}
          </Button>
        </Flex>
      </Form>
    );
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={EditUserFormSchema}
      onSubmit={submitHandler}>
      {(props) => renderActualForm(props)}
    </Formik>
  );
};
