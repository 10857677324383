import { Divider, Stack, Text } from "@chakra-ui/react";
import { Button, Select, StyledTextarea } from "app/shared";
import i18next from "i18next";
import React, { useState } from "react";
import { sendReportMail } from "../../contact/services";
import { useStoreRepositoryContext } from "../repositories";
import { useMediaQueryContext } from "styles/context";

interface Props {
  storeEmail?: string;
  senderEmail?: string;
  onClose: () => void;
}

export const ReportForm: React.FC<Props> = ({
  storeEmail,
  senderEmail,
  onClose,
}) => {
  const [text, setText] = useState("");
  const [error, setError] = useState("");

  const [showErrorText, setShowErrorText] = useState(false);
  const [showErrorSubject, setShowErrorSubject] = useState(false);

  const { storeRepository } = useStoreRepositoryContext();
  const { isSmallMedium } = useMediaQueryContext();

  return (
    <Stack direction={isSmallMedium ? "column" : "row"} spacing={4} p={2}>
      <Stack direction="column" spacing={4} width={isSmallMedium ? "100%" : "50%"}>
        <Text fontSize="lg" color="darkGrey" fontWeight="bold"
        >
          {i18next.t("stores:storeInfo.reportForm.header")}
        </Text>
        <StyledTextarea
          onChange={(e: any) => {
            setText(e.target.value);
            setShowErrorText(e.target.value.length === 0);
          }}
          value={text}
          placeholder={i18next.t(
            "stores:storeInfo.reportForm.textPlaceholder"
          )}
          height="200px"
        />
        {showErrorText && (
          <Text color="brandRed.300" fontWeight="bold">
            {i18next.t("stores:storeInfo.reportForm.errorText")}
          </Text>
        )}
        <Button
          onClick={() => {
            setShowErrorText(text.length === 0);

            if (text.length !== 0) {
              // send Mail
              sendReportMail(storeRepository?.store?.id, text)
                .then((data) => {
                  setError("");
                  onClose();
                })
                .catch((errorMessage) => {
                  setError(errorMessage)
                });
            }
          }}
          width="40%">
          {i18next.t("stores:storeInfo.reportForm.submit")}
        </Button>
        {
          error && error.length > 0 &&
          (<Text
            color="brandRed.400"
            fontWeight="bold"
          >
            {error}
          </Text>)
        }
      </Stack>
      <Divider orientation="vertical" color="black" />
      <Stack width={isSmallMedium ? "100%" : "400px"} py={4} justifyContent="center">
        <Text fontSize="lg" color="darkGrey">
          {i18next.t("stores:storeInfo.reportForm.info")}
        </Text>
      </Stack>
    </Stack>
  );
};

