import React from "react";

import { PresseRepositoryContext } from "./context";
import { PresseRepository } from "./PresseRepository";

interface Props {
  children: React.ReactNode;
}

export const PresseRepositoryProvider: React.FC<Props> = ({
  children,
}) => {
  const presseRepository = new PresseRepository();

  return (
    <PresseRepositoryContext.Provider value={{ presseRepository }}>
      {children}
    </PresseRepositoryContext.Provider>
  );
};
