import { Text, Link } from "@chakra-ui/react";
import {
  ContentArea,
  Headline,
} from "app/shared";
import { StaticPageTextBlock } from "app/staticPages/StaticPageTextBlock";
import { TestimonialResult } from "app/testimonials/testimonialResult";

import React from "react";
import i18next from "i18next";
import useScript from "../utils/useScript";



export const TestimonialsPage: React.FC = () => {

  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'testimonials', section: 'testimonials', pubdate: '', author: '', url: '/lieblingslaeden', object_type: 'testimonials' }}); } ");


  return (

    <ContentArea>
      <Headline as="h1">{i18next.t("testimonial:testimonial.headline")}</Headline>
      <StaticPageTextBlock mb={12}>
        <Text >
          {i18next.t("testimonial:testimonial.teaserText")}
        </Text>
      </StaticPageTextBlock>

      <TestimonialResult></TestimonialResult>
    </ContentArea>
  )
};
