import { action, computed, observable } from "mobx";

export class MapsRepository {
  @observable private _maps: any = undefined;

  @action
  setMaps(maps: any) {
    this._maps = maps;
  }

  @computed get maps(): any {
    return this._maps;
  }
}
