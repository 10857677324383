import { Box, BoxProps, Image, Stack } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { StaticPageTextBlock } from "./StaticPageTextBlock";

interface Props extends BoxProps {
  imgSrc: string;
  imgPosition: "left" | "right";
  children: ReactNode;
  imgWidth?: string;
  imgHeight?: string;
}

export const PhotoAndTextBlock: React.FC<Props> = ({
  imgSrc,
  imgPosition,
  children,
  imgWidth,
  imgHeight,
  ...rest
}) => {
  const flexDirection = imgPosition === "left" ? "row" : "row-reverse";
  return (
    <Stack direction={flexDirection} spacing={8} {...rest}>
      <Box>
        <Image src={imgSrc} width={imgWidth ?? ""} height={imgHeight ?? ""} />
      </Box>
      <StaticPageTextBlock>{children}</StaticPageTextBlock>
    </Stack>
  );
};
