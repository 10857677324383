import { Text } from "@chakra-ui/react";
import { ContentArea, Heading } from "app/shared";
import React from "react";
import i18next from "i18next";
import useScript from "../utils/useScript";

const NotFoundPage: React.FC = () => {
  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: '404', section: '404', pubdate: '', author: '', url: '/404', object_type: '404' }}); } ");

  return (
    <ContentArea>
      <Heading>{i18next.t("shared:notFound.title")}</Heading>
      <Text fontSize="lg" color="darkGrey">
        {i18next.t("shared:notFound.text")}
      </Text>
      <Text fontSize="lg" color="darkGrey">
        {i18next.t("shared:notFound.searchPrompt")}
      </Text>
    </ContentArea >
  )
};

export default NotFoundPage;
