import React from "react";
import CommentRepository from "./CommentRepository";
import { CommentRepositoryContext } from "./context";

interface Props {
  children: React.ReactNode;
}

export const CommentRepositoryProvider: React.FC<Props> = ({ children }) => {
  const commentRepository = new CommentRepository();

  return (
    <CommentRepositoryContext.Provider value={{ commentRepository }}>
      {children}
    </CommentRepositoryContext.Provider>
  );
};
