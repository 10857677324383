import { Text, Box, Link } from "@chakra-ui/react";
import { ContentArea, Headline, SocialInfo } from "../app/shared";
import React from "react";
import i18next from "i18next";
import useScript from "../utils/useScript";

const PrivacyPage: React.FC = () => {

  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'Datenschutzerklaerung', section: 'Datenschutzerklaerung', pubdate: '', author: '', url: '/Datenschutzerklaerung', object_type: 'Datenschutzerklaerung' }}); } ");

  return (
    <ContentArea>
      <Headline as="h1">{i18next.t("privacy:title")}</Headline>

      <Box mb="20px">
        <Text fontSize="md" color="darkGrey" mb="15px">
          {i18next.t("privacy:introText1")}
        </Text>
        <Text fontSize="md" color="darkGrey">
          {i18next.t("privacy:introText2")}
        </Text>
      </Box>

      <Box mb="20px">
        {/*1. Allgemeine Angaben*/}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section1.title")}
        </Headline>
        {/*a. Kontakt*/}
        <Box mb="20px">
          <Text fontSize="2lg" color="darkGrey" mb="15px" fontWeight="bold">
            {i18next.t("privacy:section1.subjectA.title")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section1.subjectA.line1")}
          </Text>
          <Text fontSize="md" color="darkGrey" fontWeight="bold">
            {i18next.t("privacy:section1.subjectA.line2")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("privacy:section1.subjectA.line3")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("privacy:section1.subjectA.line4")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("privacy:section1.subjectA.line5")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("privacy:section1.subjectA.line6")}
            <Link fontSize="md" color="darkGrey" href="mailTo:info@wir-liefern.org">
              {i18next.t("privacy:section1.subjectA.mail1")}
            </Link>
          </Text>
        </Box>

        {/*b. Rechtsgrundlagen*/}
        <Box mb="20px">
          <Text fontSize="2lg" color="darkGrey" mb="15px" fontWeight="bold">
            {i18next.t("privacy:section1.subjectB.title")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section1.subjectB.line1")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section1.subjectB.line2")}
          </Text>
        </Box>

        {/*b. Dauer der Speicherung*/}
        <Box mb="20px">
          <Text fontSize="2lg" color="darkGrey" mb="15px" fontWeight="bold">
            {i18next.t("privacy:section1.subjectC.title")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("privacy:section1.subjectC.line1")}
          </Text>
        </Box>

        {/*d. Kategorien von Empfängern der Daten*/}
        <Box mb="20px">
          <Text fontSize="2lg" color="darkGrey" mb="15px" fontWeight="bold">
            {i18next.t("privacy:section1.subjectD.title")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section1.subjectD.line1")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section1.subjectD.line2")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("privacy:section1.subjectD.line3")}
          </Text>
        </Box>

        {/*e. Datenübermittlung in die USA*/}
        <Box mb="20px">
          <Text fontSize="2lg" color="darkGrey" mb="15px" fontWeight="bold">
            {i18next.t("privacy:section1.subjectE.title")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section1.subjectE.line1")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section1.subjectE.line2")}
            <Link fontSize="md" target="_blank" color="darkGrey" href="https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX%3A32010D0087">
              {i18next.t("privacy:section1.subjectE.linkText1")}
            </Link>.
          </Text>
        </Box>
      </Box>

      <Box mb="20px">
        {/*2. Verarbeitung von Server-Log-Files*/}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section2.title")}
        </Headline>
        <Box mb="20px">
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section2.subjectA.line1")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("privacy:section2.subjectA.line2")}
          </Text>
        </Box>
      </Box>

      <Box mb="20px">
        {/*3. Kontaktmöglichkeit*/}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section3.title")}
        </Headline>
        <Box mb="20px">
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section3.subjectA.line1")}
          </Text>
        </Box>
      </Box>

      <Box mb="20px">
        {/*4. Registrierung und Login*/}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section4.title")}
        </Headline>
        <Box mb="20px">
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section4.subjectA.line1")}
          </Text>
        </Box>
      </Box>

      <Box mb="20px">
        {/*5. Cookies */}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section5.title")}
        </Headline>
        <Box mb="20px">
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section5.subjectA.line1")}
            <Link fontSize="md" target="_blank" color="darkGrey" href="https://www.bsi-fuer-buerger.de/BSIFB/DE/Empfehlungen/EinrichtungSoftware/EinrichtungBrowser/Sicherheitsmassnahmen/Cookies/cookies_node.html ">
              {i18next.t("privacy:section5.subjectA.linkText1")}.
            </Link>
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section5.subjectA.line2")}
          </Text>
        </Box>
      </Box>

      <Box mb="20px">
        {/*6. Consent-Management über Borlabs*/}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section6.title")}
        </Headline>
        <Box mb="20px">
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section6.subjectA.line1")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section6.subjectA.line2")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section6.subjectA.line3")}
          </Text>
        </Box>
      </Box>



      <Box mb="20px">
        {/*8. Eingebundene Dienste und Inhalte Dritter*/}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section8.title")}
        </Headline>
        <Box mb="20px">
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section8.subjectA.line1")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section8.subjectA.line2")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("privacy:section8.subjectA.line3")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("privacy:section8.subjectA.line4")}
          </Text>
        </Box>
      </Box>

      <Box mb="20px">
        {/*9. Verarbeitung bei der Ausübung Ihrer Rechte gem. Art. 15 bis 22 DSGVO*/}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section9.title")}
        </Headline>
        <Box mb="20px">
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section9.subjectA.line1")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section9.subjectA.line2")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section9.subjectA.line3")}
          </Text>
        </Box>
      </Box>

      <Box mb="20px">
        {/*10. Ihre Rechte*/}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section10.title")}
        </Headline>
        <Box mb="20px">
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section10.subjectA.line1")}
          </Text>
          <Text fontSize="md" color="darkGrey" >
            {i18next.t("privacy:section10.subjectA.line2")}
          </Text>
          <Text fontSize="md" color="darkGrey" >
            {i18next.t("privacy:section10.subjectA.line3")}
          </Text>
          <Text fontSize="md" color="darkGrey" >
            {i18next.t("privacy:section10.subjectA.line4")}
          </Text>
          <Text fontSize="md" color="darkGrey" >
            {i18next.t("privacy:section10.subjectA.line5")}
          </Text>
          <Text fontSize="md" color="darkGrey" >
            {i18next.t("privacy:section10.subjectA.line6")}
          </Text>
          <Text fontSize="md" color="darkGrey" >
            {i18next.t("privacy:section10.subjectA.line7")}
          </Text>
          <Text fontSize="md" color="darkGrey" >
            {i18next.t("privacy:section10.subjectA.line8")}
          </Text>
        </Box>
      </Box>

      <Box mb="20px">
        {/*11. Widerspruchsrecht*/}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section11.title")}
        </Headline>
        <Box mb="20px">
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section11.subjectA.line1")}
          </Text>
        </Box>
      </Box>

      <Box mb="20px">
        {/*12. AllDatenschutzhinweise zu unserer Facebook- und Instagram-Seite*/}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section12.title")}
        </Headline>
        <Box mb="20px">
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section12.introLine1")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section12.introLine2")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section12.introLine3")}
          </Text>
          <Text fontSize="md" fontWeight="bold" color="darkGrey" mb="15px">
            {i18next.t("privacy:section12.introLine4")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section12.introLine5")}
          </Text>
        </Box>
      </Box>

      <Box mb="20px">
        {/*12. LinkedIn*/}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section13.title")}
        </Headline>
        <Box mb="20px">
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section13.introLine1")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section13.introLine2")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section13.introLine3")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section13.introLine4")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section13.introLine5")}
          </Text>
        </Box>
      </Box>

      <Box mb="20px">
        {/*13. Twitter*/}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section14.title")}
        </Headline>
        <Box mb="20px">
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section14.introLine1")}
          </Text>
        </Box>
      </Box>

      <Box mb="20px">
        {/*14. Weitere Datenverarbeitungen*/}
        <Headline
          as="h2"
          fontSize={["xl", "xl", "5xl", "5xl"]}>
          {i18next.t("privacy:section15.title")}
        </Headline>
        <Box mb="20px">
          <Text fontSize="md" fontWeight="bold" color="darkGrey" mb="15px">
            {i18next.t("privacy:section15.introLine1")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section15.introLine2")}
          </Text>
          <Text fontSize="md" fontWeight="bold" color="darkGrey" mb="15px">
            {i18next.t("privacy:section15.introLine3")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section15.introLine4")}
          </Text>
          <Text fontSize="md" fontWeight="bold" color="darkGrey" mb="15px">
            {i18next.t("privacy:section15.introLine5")}
          </Text>
          <Text fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("privacy:section15.introLine6")}
          </Text>
        </Box>
      </Box>

      <SocialInfo header={"shared:socialInfo.header"} />
    </ContentArea>
  )
};

export default PrivacyPage;
