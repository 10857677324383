import { Partner, PartnerDetail } from "../models/index";
import axios from "../../../network";
/**
 * Partner
 */


export const getPartner = async (typename:string): Promise<Partner[]> => {
  return new Promise<Partner[]>((resolve, reject) => {
    axios.
      get("/Partners/Search")
      .then(function (response) {
        try {
          const partnerData: Partner[] = response.data;
          resolve(partnerData);
        } catch (r) {
          console.log("Error loading/parsing PartnerList data");
        }
      }).catch(() => {
        console.log("Error loading/parsing PartnerList data");
      });
  });
};

export const getPartnerDetails = async (id: string): Promise<PartnerDetail> => {
  return new Promise<PartnerDetail>((resolve, reject) => {
    
    axios.
      get("/Partners/Partner/" + id)
      .then(function (response) {
        try {
          const partner: PartnerDetail = response.data;         
          resolve(partner);
        } catch (r) {
          console.log("Error loading/parsing PartnerDetails data");
        }
      }).catch(() => {
        console.log("Error loading/parsing PartnerDetails data");
      });
  });
};
