import React, { useContext } from "react";
import { PartnerRepository } from "./PartnerRepository";

interface RepositoryContextProps {
  partnerRepository: PartnerRepository;
}

export const PartnerRepositoryContext = React.createContext<
  RepositoryContextProps
>({} as RepositoryContextProps);

export const usePartnerRepositoryContext = () =>
  useContext(PartnerRepositoryContext);
