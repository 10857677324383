import React from "react";

import { NewsRepositoryContext } from "./context";
import { NewsRepository } from "./NewsRepository";

interface Props {
  children: React.ReactNode;
}

export const NewsRepositoryProvider: React.FC<Props> = ({
    children,
}) => {
  const newsRepository = new NewsRepository();

  return (
    <NewsRepositoryContext.Provider value={{ newsRepository }}>
      {children}
    </NewsRepositoryContext.Provider>
  );
};
