import { ContentArea } from "app/shared";
import React from "react";
import CreateStoreForm from "app/stores/forms/CreateStoreForm";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { Stack } from "@chakra-ui/react";
import { useRootRepositoryContext } from "rootRepository";
import { CreateUserForm } from "app/users/forms/CreateUserForm";
import { User } from "app/users/models";
import useScript from "../utils/useScript";

const CreateBusiness: React.FC<{ user: User }> = ({ user }) => (
  <Stack spacing={20} direction="column">    
    <CreateStoreForm />
  </Stack>
);

const CreateUserAndStorePageBase: React.FC = () => {
  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'newUserAndStore', section: 'newUserAndStore', pubdate: '', author: '', url: '/newUserAndStore', object_type: 'newUserAndStore' }}); } ");

  const { userRepository } = useRootRepositoryContext();
  const { user } = userRepository;

  return (
    <ContentArea>
      {user ? <CreateBusiness user={user} /> : <CreateUserForm setCreateStore={true} />}
    </ContentArea>
  );
};

export const CreateUserAndStorePage = compose<{}, {}>(
  withRouter,
  observer
)(CreateUserAndStorePageBase);
