import React from "react";
import { StoreRepositoryContext } from "./context";
import StoreRepository from "./StoreRepository";

interface Props {
  children: React.ReactNode;
}

export const StoreRepositoryProvider: React.FC<Props> = ({ children }) => {
  const storeRepository = new StoreRepository();

  return (
    <StoreRepositoryContext.Provider value={{ storeRepository }}>
      {children}
    </StoreRepositoryContext.Provider>
  );
};
