import { ContentArea } from "app/shared";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { PartnerRepositoryProvider } from "app/partner/repositories";
import { PartnerDetailsInfo } from 'app/partner/partnerDetail';
import useScript from "../utils/useScript";
interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

const PartnerDetailsBase: React.FC<Props> = ({ match }) => {
  const id = match.params.id;

  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: '" + id + "', section: 'partnerDetail', pubdate: '', author: '', url: '/partnerDetail', object_type: 'partnerDetail' }}); } ");

  return (
    <ContentArea >
      <PartnerRepositoryProvider >
        <PartnerDetailsInfo partnerId={id} ></PartnerDetailsInfo>
      </PartnerRepositoryProvider>
    </ContentArea>
  );
};

export const PartnerDetails = compose<Props, {}>(withRouter)(PartnerDetailsBase);
