import { AspectRatio, AspectRatioProps, Box } from "@chakra-ui/react";
import React from "react";

interface Props extends AspectRatioProps {
  src: string;
}

// Box outside to fix problem with Flex and AspectRatioBox
export const EmbeddedVideo: React.FC<Props> = ({
  src,
  ratio,
  title,
  ...rest
}) => (
    <Box mb = {0}>
    <AspectRatio ratio={ratio ?? 9 / 5} {...rest}>
      <Box
        as="iframe"
        title={title}
        {...{
          src: src,
          allowFullScreen: true,
        }}
      />
    </AspectRatio>
  </Box>
);
