import React from "react";

import { PartnerRepositoryContext } from "./context";
import { PartnerRepository } from "./PartnerRepository";

interface Props {
  children: React.ReactNode;
}

export const PartnerRepositoryProvider: React.FC<Props> = ({
    children,
}) => {
  const partnerRepository = new PartnerRepository();

  return (
    <PartnerRepositoryContext.Provider value={{ partnerRepository }}>
      {children}
    </PartnerRepositoryContext.Provider>
  );
};
