import { Box, BoxProps, Grid, Stack, Flex } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React from "react";
import { compose } from "recompose";
import { useRootRepositoryContext } from "rootRepository";
import { SubCategory } from "../models";
import { CategoryCard } from "./CategoryCard";
import { SubCategoryBox } from "./SubCategoryBox";

interface Props extends BoxProps {
  category: string;
  selectedSubCategories: string[];
  onCategoryRemoved: () => void;
  onSubCategorySelected: (subCategories: string[]) => void;
}

interface FormattedSubCategories {
  id: string;
  name: string;
  selected: boolean;
}

const SubCategoryPickerBase: React.FC<Props> = ({
  category,
  selectedSubCategories,
  onCategoryRemoved,
  onSubCategorySelected,
}) => {
  const { categoryRepository } = useRootRepositoryContext();

  const categoryName: string = categoryRepository.categories.find((c) => c.id === category)?.categoryName ?? "";

  const subCategories: SubCategory[] =
    categoryRepository.categories.find((c) => c.id === category)
      ?.subCategories ?? [];

  const formattedSubCategories: FormattedSubCategories[] =
    subCategories.map((subCategory) => ({
      id: subCategory.id,
      name: subCategory.name,
      selected: selectedSubCategories?.includes(subCategory.id) ?? false,
    })) ?? [];

  return (
    <Stack direction="row" spacing={4} alignItems="flex-start">
      <CategoryCard category={categoryName} onRemove={() => onCategoryRemoved()} />
      <Box
        width={["150px", "200px", "200px", "370px", "unset"]}
        maxHeight={["170px", "170px", "170px", "unset", "unset"]}
        overflowY={["scroll", "scroll", "scroll", "unset", "unset"]}>
        <Flex
          width={["140px", "180px", "180px", "370px", "unset"]}
          justifyContent="flex-start"
          flexWrap="wrap"

        >        
          {formattedSubCategories.map((subCategory) => (
            <SubCategoryBox
              key={`subCategoryBox_${subCategory.id}`}
              id={subCategory.id}
              name={subCategory.name}
              selectedSubCategories={selectedSubCategories}
              onSubCategorySelected={onSubCategorySelected}
              
              selected={subCategory.selected}
            />
          ))}
        </Flex>
      </Box>
    </Stack>
  );
};

export const SubCategoryPicker = compose<Props, Props>(observer)(
  SubCategoryPickerBase
);

/*

<Grid
          templateColumns="repeat(4, 118px)"
          templateRows="repeat(4, 27px)"
          gap={2}>
          {formattedSubCategories.map((subCategory) => (
            <SubCategoryBox
              key={`subCategoryBox_${subCategory.id}`}
              id={subCategory.id}
              name={subCategory.name}
              selectedSubCategories={selectedSubCategories}
              onSubCategorySelected={onSubCategorySelected}

              selected={subCategory.selected}
            />
          ))}
        </Grid>
*/
