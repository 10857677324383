import { BoxProps, Box, Text } from "@chakra-ui/react";
import { OutsideClickWrapper } from "app/shared/wrapper/OutsideClickWrapper";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { LoginForm } from "./LoginForm";

interface PropsExternal extends BoxProps {
  onClose?: () => void;
}

interface Props extends PropsExternal, RouteComponentProps {}

const LoginMenuBase: React.FC<Props> = ({ history, color, onClose }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    history.listen(() => {
      open && setOpen(false);
    });
  });

  return (
    <OutsideClickWrapper isOpen={open} callback={() => setOpen(false)}>
      <Box _hover={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
        <Text color={color ?? "brandRed.300"} fontWeight="bold" fontSize="xl">
          {i18next.t("shared:navbar.loginMenu.login")}
        </Text>
      </Box>
      {open && <LoginForm onLogin={() => setOpen(false)} onClose={onClose} />}
    </OutsideClickWrapper>
  );
};

export const LoginMenu = compose<Props, PropsExternal>(withRouter)(
  LoginMenuBase
);
