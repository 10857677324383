import i18next from "i18next";
import debounce from "lodash.debounce";
import React, { Component } from "react";
import { Coords } from "./models";
import { StyledInput } from "../forms/StyledComponents";
import { clearLine } from "readline";


interface Props {
  onChange: (location: Coords, selectedPlace: any) => void;
  onBlur?: (isEmpty: boolean) => void;
  initialValue?: string;
  maps: any; // google maps API dependency
  disabled?: boolean;
  handleChange?: (value: string) => void;
  value?: string;
}

/**
 * https://github.com/google-map-react/google-map-react/issues/460
 */
export class GoogleSearchBox extends Component<Props, {}> {
  searchInputRef: React.RefObject<any>;
  searchBox: any;
  inputValue?: string;


  constructor(props: Props) {
    super(props);
    this.searchInputRef = React.createRef();
    this.createSearchBox();
    //this.inputValue = props.initialValue;
  }

  /**
   * Create SearchBox when maps api is provided.
   */
  componentDidUpdate() {
    this.createSearchBox();
  }

  /**
   * Clear input on unmount.
   */
  componentWillUnmount() {
    const { maps } = this.props;

    if (this.searchBox) {
      maps.event?.clearInstanceListeners?.(this.searchBox);
    }
  }

  /**
   * To re-use the component after componentWillUnmount is called a timeout is need before creating a new SearchBox.
   */
  createSearchBox() {
    const { maps } = this.props;

    if (maps?.places?.SearchBox && !this.searchBox) {
      debounce(() => {
        this.searchBox = new maps.places.SearchBox(this.searchInputRef.current);
        this.searchBox.addListener(
          "places_changed",
          this.onPlacesChanged.bind(this)
        );
        try {

          if ((this.searchInputRef.current.value == undefined || this.searchInputRef.current.value == "") && this.props?.initialValue !== undefined && this.props?.initialValue !== "") {
            this.searchInputRef.current.value = this.props.initialValue;
          }
        } catch (e) {
          
        }
      }, 500)();
    }
  }

  onPlacesChanged() {

    if (this.searchBox?.getPlaces) {
      const place = this.searchBox.getPlaces()?.[0]; // assume first result

      if (place) {
        this.props.onChange(
          {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
          place
        );

        if (this.props.handleChange) {
          const address = place.formatted_address;
          this.props.handleChange(address);
        }

      }
    }
  }

  render() {
    const { disabled, maps, initialValue } = this.props;

    return (
      <StyledInput
        width="100%"
        type="text"
        fontSize="lg"
        placeholder={i18next.t("shared:googleSearchBox.placeholder")}
        ref={this.searchInputRef}
        //isDisabled={disabled || !maps}
        variant="unstyled"
        px={3}
        py={2}
        bg="white"
        value={this.inputValue}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          if (this.props.handleChange) {
            this.props.handleChange(e.currentTarget.value);
          }
          if (this.props.onBlur != undefined) {
            if (e.currentTarget.value == "") {
              this.props.onBlur(true);
            }
            else {
              this.props.onBlur(false);
            }
          }
          //this.inputValue = e.currentTarget.value
        }}
        onBlur={(e: React.FormEvent<HTMLInputElement>) => {
          //if (this.props.onBlur != undefined) {
          //  if (e.currentTarget.value == "") {
          //    this.props.onBlur(true);
          //  }
          //  else {
          //    this.props.onBlur(false);
          //  }
          //}
        }}
      />
    );
  }
}
