import { computed, flow, observable } from "mobx";
import { Campaign } from "../models/index";
import { getCampaign } from "../services/index";

type Status = "successful" | "pending" | "error";

export class CampaignRepository {
  @observable private _campaign: Campaign = {} as any;
  @observable private _status: Status = "pending";

  fetchCampaign = flow(function* (this: CampaignRepository) {
    try {
      const campaign: Campaign = yield getCampaign();

      this._status = "successful";
      this._campaign = campaign;
    } catch (error) {
      this._status = "error";
    }
  }).bind(this);

  @computed get campaign(): Campaign {
    return this._campaign;
  }

  @computed get status(): Status {
    return this._status;
  }
}
