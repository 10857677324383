import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { GoogleSearchBox } from "./GoogleSearchBox";
import { Map } from "./Map";
import { Coords } from "./models";

interface Props {
  onChange: (coords: Coords) => void;
  handleChange?: (value: string) => void;
}

export const GoogleSearchBoxNoMap: React.FC<Props> = ({ onChange, handleChange }) => {
  const [googleMapsApi, setGoogleMapsApi] = useState<any>(undefined);

  return (
    <>
      <GoogleSearchBox maps={googleMapsApi} onChange={onChange} handleChange={handleChange} />
      <Box display="none">
        <Map onGoogleApiLoaded={(maps) => setGoogleMapsApi(maps)} />
      </Box>
    </>
  );
};
