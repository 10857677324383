import { ContentArea } from "app/shared";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { NewsRepositoryProvider } from "app/news/repositories";
import { NewsDetailsInfo } from 'app/news/newsDetail';
import useScript from "../utils/useScript";
interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

const NewsDetailsBase: React.FC<Props> = ({ match }) => {
  const id = match.params.id;

  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: '" + id + "', section: 'newsDetail', pubdate: '', author: '', url: '/newsDetail', object_type: 'newsDetail' }}); } ");

  return (
    <ContentArea >
      <NewsRepositoryProvider >
        <NewsDetailsInfo newsId={id}></NewsDetailsInfo>
      </NewsRepositoryProvider>
    </ContentArea>
  );
};

export const NewsDetails = compose<Props, {}>(withRouter)(NewsDetailsBase);
