import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { MarkerRightIcon } from "styles/icons";

interface Props {
  href?: string;
  name: string;
  onClick?: () => void;
  iconVisible?: boolean;
}

export const DrawerMenuLink: React.FC<Props> = ({
  href,
  name,
  onClick,
  iconVisible,
}) => (
  <Flex direction="row" justifyContent="space-between" alignItems="center">
    <Link to={href ? href : ""} onClick={onClick}>
      <Text fontWeight="bold" fontSize={["xl", "2xl", "2xl", "5xl"]}>
        {name}
      </Text>
    </Link>
    {iconVisible && <MarkerRightIcon color="white" boxSize="22px" />}
  </Flex>
);
