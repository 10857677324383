interface SocialNetwork {
  name: "facebook" | "instagram" | "whatsapp" | "twitter";
  link: string;
}

export const SocialNetworks: SocialNetwork[] = [
  {
    name: "facebook",
    link: "https://www.facebook.com/wirliefernorg/",
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/wir.liefern/",
  },
  {
    name: "whatsapp",
    link: "",
  },
  {
    name: "twitter",
    link: "https://twitter.com/Wirliefernorg",
  },
];
