import { Box, Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { compose } from "recompose";
import { Headline } from "app/shared";
import i18next from "i18next";
import { PartnerCard } from "./PartnerCard";
import { Partner } from "../models/index";
import { usePartnerRepositoryContext } from "../repositories";
import { useMediaQueryContext } from "styles/context";


interface Props {
}

const PartnerResultsBase: React.FC<Props> = () => {
  const { partnerRepository } = usePartnerRepositoryContext();
  const { isSmall, isSmallMedium } = useMediaQueryContext();

  let partner: Partner[] = partnerRepository.partner;

  return (
    <Box>
      <Flex
        direction={[
          "column",
        ]}
        justifyContent="space-between">

        {partner.map((partnerItem) => (

          <PartnerCard
            imgSrc={partnerItem.imageURL}
            imgSrc2={""}
            imgPosition={"left"}
            text={partnerItem.profileShort}
            key={partnerItem.id}
            id={partnerItem.id}
            headline={partnerItem.partner}
            mb={isSmallMedium ? "50px" : "35px"}
          />
        ))}

      </Flex>
    </Box>
  );
};

export const PartnerResult = compose<Props, Props>(observer)(PartnerResultsBase);
