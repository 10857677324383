import {
  CreateUserFormViewModel,
  EditUserFormViewModel,
  ResetUserPasswordFormViewModel,
  RequestResetUserPasswordFormViewModel,
  ResetUserPasswordPublicFormViewModel,
  IUserLoginSchema,
  IUserLoginResponseSchema,
} from "../forms/UserFormSchema";
import axios from "network";
import { User } from "app/users/models";
import { api } from "configurations/development";
import Cookies from "js-cookie";
import environment from "../../../configurations";
import { useRootRepositoryContext } from "rootRepository";


export const getUser = async (
  values: IUserLoginResponseSchema):
  Promise<User> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Users/GetUser`, values)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response?.data?.Code == 999) {
          reject(response.data.Message);
        }
        else {
          resolve(response?.data as User);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      }).
      finally(() => {

      });;
  });
};

export const doLogin = async (
  values: IUserLoginSchema
): Promise<IUserLoginResponseSchema> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/OAuth2/Token`, values)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response.data.Code == 999) {
          reject(response.data.Message);
        }
        else {
          var r = response.data as IUserLoginResponseSchema;
          // Cookie persists for 3 hours - editing requires an edit in API as well
          Cookies.set(`${environment.cookieKey}`, JSON.stringify(r),
            { secure: true, expires: new Date(new Date().setDate(new Date().getDate() + r.expires_in)) });
          resolve(r);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  })
};

export const createUser = async (
  values: CreateUserFormViewModel
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/Users/AddUser`, values)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response.data.Code == 999) {
          reject(response.data.Message);
        }
        else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

export const doiUser = async (id: string): Promise<boolean> => {
  return await new Promise((resolve, reject) => {
    axios
      .post(`/Users/DoiUser?id=` + id)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response.data.Code == 999) {
          reject(response.data.Message);
        }
        else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

export const resetPasswordUser = async (values: ResetUserPasswordFormViewModel, id: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/Users/ResetPassword`, { OldPassword: values.oldpassword, Password: values.password, id: id })
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response.data.Code == 999) {
          reject(response.data.Message);
        }
        else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

export const resetPasswordPublicUser = async (values: ResetUserPasswordPublicFormViewModel, id: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/Users/ResetPasswordPublic`, { Password: values.password, id: id })
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response.data.Code == 999) {
          reject(response.data.Message);
        }
        else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

export const resetPasswordPublicRequestUser = async (values: RequestResetUserPasswordFormViewModel): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Users/RequestResetPasswordPublic?email=` + values.email )
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response.data.Code == 999) {
          reject(response.data.Message);
        }
        else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

export const uploadUserPicture = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append("picture", file);

    return axios
      .post(`/users/UpdateUserPicture`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          //throw Error(errorMessage);
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

export const editUser = async (values: EditUserFormViewModel
): Promise<User> => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/Users/UpdateUser`, values)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response.data.Code == 999) {
          reject(response.data.Message);
        }
        else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};
