import { Input, InputProps, Select, Textarea } from "@chakra-ui/react";
import styled from "@emotion/styled";
import themeGet from "@styled-system/theme-get";

/**
 * Contains the styled chakra components that should be used across the app.
 */
export const StyledTextarea = styled(Textarea)<InputProps>`
  border: 3px solid ${themeGet("colors.brandRed.300")};
  border-radius: 0;
`;
StyledTextarea.defaultProps = {
  _hover: undefined,
  _focus: undefined,
};

export const StyledInput = styled(Input)<InputProps>`
  border: 3px solid ${themeGet("colors.brandRed.300")};
  border-radius: 0;
`;

export const StyledSelect = styled(Select)<InputProps>`
  border: 3px solid ${themeGet("colors.brandRed.300")};
  border-radius: 0;
  color: black;
`;
