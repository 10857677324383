import React from "react";
import { SearchParams } from "../models";
import { SearchRepositoryContext } from "./context";
import { SearchRepository } from "./SearchRepository";

interface Props {
  children: React.ReactNode;
  searchParams: SearchParams;
}

export const SearchRepositoryProvider: React.FC<Props> = ({
  children,
  searchParams,
}) => {
  const searchRepository = new SearchRepository(searchParams);

  return (
    <SearchRepositoryContext.Provider value={{ searchRepository }}>
      {children}
    </SearchRepositoryContext.Provider>
  );
};
