import { Stack, Text } from "@chakra-ui/react";
import i18next from "i18next";
import React from "react";

export const OnlyPickup: React.FC = () => (
  <Stack
    zIndex={1}
    borderRadius="50%"
    position="absolute"
    bg="brandRed.300"
    w="79px"
    h="79px"
    left={0}
    justifyContent="center"
    alignItems="center"
    color="white">
    <Text
      textAlign="center"
      fontSize="2xs"
      fontWeight="bold"
      wordBreak="break-all"
      lineHeight="none"
      transform="rotate(-16.11deg)">
      {i18next.t("search:searchResults.onlyPickup")}
    </Text>
  </Stack>
);
