import { Category, CategoryDTO, MetaCategory } from "../models";

interface FormattedCategoriesDTO {
  metaCategories: MetaCategory[];
  categories: Category[];
}

export const formatCategoriesDTO = (
  categoryDTO: CategoryDTO[]
): FormattedCategoriesDTO => {
  const metaCategories = categoryDTO.reduce((acc: MetaCategory[], item) => {
    const metaCategoryEntry: any = acc.find(
      (it: MetaCategory) => it.metaCategoryName === item.metaCategoryName
    );
    if (!metaCategoryEntry) {
      acc.push({
        metaCategoryName: item.metaCategoryName,
        categories: [item.categoryName],
      });
    } else {
      metaCategoryEntry.categories.push(item.categoryName);
    }

    return acc;
  }, []);

  const categories = categoryDTO.reduce((acc: Category[], item) => {
    const categoryEntry: any = acc.find(
      (it: Category) => it.categoryName === item.categoryName
    );

    if (!categoryEntry) {
      acc.push({
        categoryName: item.categoryName,
        subCategories: item.subCategories,
        id: item.categoryId
      });
    }

    return acc;
  }, []);

  return { metaCategories, categories };
};
