import { Box, Flex, FlexProps, Text } from "@chakra-ui/react";
import { Heading, Headline } from "app/shared";
import React, { ReactNode } from "react";

interface Props extends FlexProps {
  title: string;
  button?: any;
}

export const Section: React.FC<Props> = ({
  title,
  button,
  children,
  ...rest
}) => (
  <Flex direction="column" bg="lightestGrey" borderRadius="5px" {...rest}>
    <Flex px="30px" py="15px">
      <Text fontWeight="bold" fontSize="xl" color="darkGrey">
        {title}
      </Text>
      {button != null && button != undefined && <Box ml="auto">{button}</Box>}
    </Flex>
    <Box bg="white" height="5px"></Box>
    <Box px="30px" py="30px" minHeight="100px">
      {children}
    </Box>
  </Flex>
);
