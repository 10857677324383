import { FieldInternalProps, DEFAULT_LABEL_WIDTH } from "./GenericInput";
import { Field } from "formik";
import React from "react";
import { FormControl, Flex, Box, FormLabel } from "@chakra-ui/react";
import i18next from "i18next";
import { StyledSelect } from "./StyledComponents";

export interface GenericSelectProps<T> {
  fieldName: string;
  fieldType: string;
  options: T[];
  i18nextPrefix: string;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidthPx?: string;
  flexDirection?: string;
}

export const GenericSelect = <T extends any>(props: GenericSelectProps<T>) => {
  const { fieldName, fieldType, options, i18nextPrefix } = props;
  const disabled = props.disabled ?? false;
  const hideLabel = props.hideLabel ?? false;
  const labelWidthPx = props.labelWidthPx || DEFAULT_LABEL_WIDTH;

  return (
    <Flex mt={3} flexDirection={props.flexDirection ? props.flexDirection === "row" ? "row" : "column" : "row"}>
      <Box width={labelWidthPx}>
        {!hideLabel && (
          <FormLabel>
            {i18next.t(`${i18nextPrefix}:form.fields.${fieldName}.label`)}
          </FormLabel>
        )}
      </Box>
      <Box flex="1">
        <Field className="block" name={fieldName} type={fieldType}>
          {(renderProps: FieldInternalProps) => (
            <FormControl
              isInvalid={
                renderProps.form.errors[fieldName] &&
                renderProps.form.touched[fieldName]
              }>
              <StyledSelect
                isDisabled={disabled}
                onChange={(e: any) => {
                  renderProps.form.setFieldValue(
                    fieldName,
                    fieldType === "boolean"
                      ? e.target.value === "true"
                      : e.target.value
                  );
                  renderProps.form.setFieldTouched(fieldName, true);
                }}>
                {options.map((it: any) => (
                  <option value={it} key={it} selected={it == renderProps.field.value}>
                    {i18next.t(
                      `${i18nextPrefix}:form.fields.${fieldName}.options.${it}`
                    )}
                  </option>
                ))}
              </StyledSelect>
            </FormControl>
          )}
        </Field>
      </Box>
    </Flex>
  );
};
