import { Box, BoxProps } from "@chakra-ui/react";
import React, { ReactNode, useEffect, useState } from "react";
import { useMediaQueryContext } from "styles/context";
import { layoutSizes } from "styles/theme";

interface Props extends BoxProps {
  children: ReactNode;
  isInPopup?: boolean;

}

/**
 * This component will guarantee that menu's are aligned at the right bellow the end of the main layout.
 * This component adds window resize listener to dynamically update its position when window is resized.
 */
//TODO add responsive
export const MenuWrapper: React.FC<Props> = ({
  isInPopup,
  children,
  maxWidth,

  ...rest
}) => {
  const { isMediumLarge } = useMediaQueryContext();
  const isMobile = isMediumLarge;
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const onResize = () => {
    if (window.innerWidth !== innerWidth) {
      setInnerWidth(window.innerWidth);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  //210px since safe padding on content area is 105px for each side
  return (
    <Box
      width={["100%", null]}
      maxWidth={["100%", maxWidth ? maxWidth : null]}
      position={isInPopup === undefined || !isInPopup ? "absolute" : "static"}
      boxShadow={isInPopup === undefined || !isInPopup ? "lg" : "none"}
      right={
        isMobile
          ? 0
          : `calc((${innerWidth}px - ${layoutSizes.maxWidth} + 210px) / 2)`
      }
      top={`calc(${layoutSizes.navbarHeight.lg})`}
      zIndex={10}
      {...(rest as any)}>
      {children}
    </Box>
  );
};
