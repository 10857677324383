import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import i18next from "i18next";
import { Stack, Text } from "@chakra-ui/react";
import { compose } from "recompose";
import { ContentArea } from "app/shared";
import { EditStoreWindowForm } from "app/storeWindows/forms/EditStoreWindowForm";
import { StoreWindowRepositoryProvider } from "app/storeWindows/repositories";
import { useRootRepositoryContext } from "rootRepository";
import useScript from "../utils/useScript";

interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

const EditProductPageBase: React.FC<Props> = ({ match }) => {
  useScript.useScriptViaString(
    "if (typeof upScore == 'function') { upScore({data: {object_id: 'editStoreWindow', section: 'editStoreWindow', pubdate: '', author: '', url: '/editStoreWindow', object_type: 'editStoreWindow' }}); } "
  );

  const id = match.params.id;
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  return (
    <ContentArea>
      {user ? (
        <StoreWindowRepositoryProvider>
          <Stack spacing={20} direction="column">
            {/* REQUIRES ACCESS TO THE LOGGED IN USE: <ProfileInfo editDisabled={false} />*/}
            <EditStoreWindowForm userId={user.id} storeWindowId={id} />
          </Stack>
        </StoreWindowRepositoryProvider>
      ) : (
        <Text>{i18next.t("shared:loginRequired")}</Text>
      )}
    </ContentArea>
  );
};

export default compose<Props, {}>(withRouter)(EditProductPageBase);
