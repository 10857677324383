import React from "react";
import { observer } from "mobx-react";
import { compose } from "recompose";
import i18next from "i18next";
import { Text } from "@chakra-ui/react";
import { useRootRepositoryContext } from "rootRepository";
import { ContentArea } from "app/shared";
import CreateProductForm from "app/products/forms/CreateProductForm";

const CreateProductPageBase: React.FC = () => {
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  return (
    <ContentArea>
      {user ? (
        <CreateProductForm />
      ) : (
        <Text>{i18next.t("shared:loginRequired")}</Text>
      )}
    </ContentArea>
  );
};

export default compose<{}, {}>(observer)(CreateProductPageBase);
