import React, { useContext } from "react";
import { PresseRepository } from "./PresseRepository";

interface RepositoryContextProps {
  presseRepository: PresseRepository;
}

export const PresseRepositoryContext = React.createContext<
  RepositoryContextProps
>({} as RepositoryContextProps);

export const usePresseRepositoryContext = () =>
  useContext(PresseRepositoryContext);
